import { useEffect, useState } from "react";
import { Alert, Button, ListGroup, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { useGetAccounts } from "../AzAdAccess/accounts";
import { config, getCategory, getCategoryNames } from "../Config/config";
import { CsvHeaderAssignment } from "../UIElements/CsvHeaderAssignment";
import { DropzoneCsv } from "../UIElements/DropzoneCsv";
import { LogDisp } from "../UIElements/LogDisp";
import { Selector, SelectorItem } from "../UIElements/Selector";
import { Tag, TagGroup } from "../UIElements/Tags";
import { createdAccountDb, makeSetUid, saveAccountToDb } from "../Utils/createdAccountDb";
import { createUserAccount } from "../Utils/createUser";
import { readCsvFile, saveToCsvFile } from "../Utils/csvUtils";
import { epochTimeMs, fileNameWithDate, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";
import { updUser } from "../Utils/updateUser";
import { OcDisplayCsv } from "./OcDisplayCsv";
import { OcFindGroup } from "./OcFindGroup";


export const ImportAccounts = () => {

    const logTopic = 'Konten importieren';
    const app = useAppContext();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [fields, setFields] = useState<string[]>();
    const [csvData, setCsvData] = useState<any[]>([]);
    const [csvFileName, setCsvFileName] = useState('');
    const [action, setAction] = useState('');
    const [isActionOngoing, setIsActionOngoing] = useState(false);
    const [progressAction, setProgressAction] = useState(0);
    const [assignedFields, setAssignedFields] = useState<{ [name: string]: string }>({});
    const { start, reset, accounts, isLoading, maxCount, count } = useGetAccounts(app.authProvider!);
    const [logDispStartTime, setLogDispStartTime] = useState(0);
    const [showDispCsv, setShowDispCsv] = useState(false);
    const [groups, setGroups] = useState<{ name: string, id: string }[]>([]);
    const [showFindGroup, setShowFindGroup] = useState(false);

    const cat = getCategory(selectedCategory);

    const importActions: SelectorItem[] = [
        { value: 'upd', name: 'Konten aktualisieren' },
        { value: 'new', name: 'Neue Konten hinzufügen' }
    ];

    const actionHelp: { [a: string]: string } = {
        'upd': 'Hinweis: Bestehende Konten werden aktualisiert. Es werden keine Konten gelöscht oder neue Konten angelegt.',
        'new': 'Hinweis: Neue Konten werden hinzugefügt. Bestehende Konten werden weder gelöscht noch aktualisiert.'
    }

    const doAction = async () => {
        reset();
        setIsActionOngoing(true);
        setLogDispStartTime(epochTimeMs());
        setProgressAction(0);
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
        const setUid = makeSetUid(selectedCategory);
        let count = 0;
        for (const row of csvData) {
            if (action === 'upd') {
                const updRes = await updUser(app.authProvider!, cat, row, assignedFields, logTopic, addLogEntry);
                if (!updRes) {
                    setIsActionOngoing(false);
                    break;
                }
            } else if (action === 'new') {
                const createRes = await createUserAccount(app.authProvider!, cat, row, assignedFields, groups, logTopic, addLogEntry);
                if (createRes.res) {
                    saveAccountToDb(setUid, createRes.user!);
                }
            }
            ++count;
            if (csvData.length > 0) {
                setProgressAction(Math.round(100.0 * count / csvData.length));
            }
        }
        setIsActionOngoing(false);

        // Read back accounts form local storage
        const createdAccounts = await createdAccountDb.accounts
            .where('setUid').equals(setUid)
            .toArray();
        const ids = createdAccounts.map(ac => ac.userId!);
        const passwords = createdAccounts.map(ac => ac.password!);
        start(selectedCategory, ids, passwords);
    }

    const exportProgress = (): number => {
        if (maxCount > 0) {
            return Math.round(100 * count / maxCount)
        }
        return 0;
    }

    useEffect(() => {
        if (accounts.length > 0) {
            const fn = fileNameWithDate(`Neue_${cat?.exportName!}`, 'csv');
            saveToCsvFile(fn, accounts, config.csvDelimiter);
            addLogEntry(LogEntryType.Info, logTopic, `Die neu angelegten Konten wurden gespeichert: ${fn}`);
            addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    useEffect(() => {
        if (cat) {
            setGroups([
                { name: cat.mainGroup.name, id: cat.mainGroup.id },
                { name: cat.licenseGroup.name, id: cat.licenseGroup.id }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cat]);


    return (
        app.user! &&
        <div className="page-margin page-maxwidth">
            <h3>Konten importieren</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.write.bulk" >
                <ListGroup>
                    <ListGroup.Item className="border-0">
                        <Selector title="Kategorie auswählen ..." items={getCategoryNames()} onItemChanged={e => {
                            setSelectedCategory(e.currentTarget.value);
                        }} value={selectedCategory} />
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0}>
                        <DropzoneCsv onDrop={(acceptedFiles) => {
                            setCsvFileName(acceptedFiles[0].name);
                            readCsvFile(acceptedFiles[0], (res) => {
                                setFields(res.meta.fields);
                                setCsvData(res.data);
                            });
                        }} />
                        {csvFileName &&
                            <span style={{ verticalAlign: "bottom" }}>
                                CSV-Datei:&nbsp;
                                <Button variant="link" style={{ padding: 0 }} onClick={() => setShowDispCsv(true)}>
                                    {csvFileName}
                                </Button>
                                <br />
                                Anzahl der zu importierenden Konten: {csvData.length}
                            </span>
                        }
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0 || !fields || fields.length === 0}>
                        <div>
                            <CsvHeaderAssignment
                                availableFields={fields!}
                                requiredFields={cat?.importHeader!}
                                titleAvailableFields="Felder der importierten CSV-Datei:"
                                titleAssignment="Zugewiesene Felder:"
                                onAssignment={(assignment) => {
                                    setAssignedFields(assignment);
                                }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0 || !fields || fields.length === 0}>
                        <Selector title="Aktion auswählen ..." items={importActions} onItemChanged={e => {
                            setAction(e.currentTarget.value);
                        }} />
                        <p><small><i>{actionHelp[action]}</i></small></p>
                        <div hidden={action !== 'new'}>
                            <TagGroup title="Gruppen (für alle Konten)"
                                onAdd={() => {
                                    setShowFindGroup(true);
                                }}>
                                {groups && groups.map((g) =>
                                    <Tag
                                        key={g.id} name={g.name} onClick={() => {
                                            const upd = [...groups];
                                            const idx = upd.indexOf(g);
                                            if (idx >= 0) {
                                                upd.splice(idx, 1);
                                                setGroups(x => [...upd]);
                                            }
                                        }}
                                    />
                                )}
                            </TagGroup>
                            <p />
                        </div>
                        <div className="d-flex flex-row justify-content-between" hidden={action.length === 0}>
                            <Button className="mr-3" onClick={doAction} disabled={isActionOngoing}>Aktion ausführen ...</Button>
                            <Button className="mr-3" disabled={isActionOngoing} variant="secondary" onClick={() => {
                                setIsActionOngoing(false);
                                setCsvData([]);
                                setCsvFileName('');
                                setFields([]);
                                setSelectedCategory('');
                            }}>
                                Formular zurücksetzen
                            </Button>
                        </div>

                        <p />
                        <div hidden={!(isActionOngoing || isLoading || accounts.length > 0)} >
                            Konten werden angelegt ...
                            <ProgressBar now={progressAction} label={`${progressAction}%`} />
                            <p />
                        </div>
                        <div hidden={!(isLoading || accounts.length > 0)} >
                            Neu angelegte Konten werden exportiert ...
                            <ProgressBar now={exportProgress()} label={`${exportProgress()}%`} />
                            <p />
                        </div>
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.write.bulk">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
            <OcDisplayCsv header={fields!} data={csvData} title={csvFileName}
                show={showDispCsv} placement="bottom" onHide={() => setShowDispCsv(false)} />
            <OcFindGroup show={showFindGroup} placement="end" onHide={() => setShowFindGroup(false)}
                onAdd={(grp) => {
                    const g = { name: grp.displayName!, id: grp.id! }
                    const upd = [...groups];
                    const fg = upd.find(o => o.id === g.id)
                    if (fg) {
                        return false;
                    }
                    upd.push(g);
                    setGroups(x => [...upd]);
                    return true;
                }}
            />
        </div>
    );
}