import { AppRole, useAppContext } from "./AppContext";

export interface AppRolesAssignedProps {
    appRoles: AppRole[];
    requiredRoles: string[];
    children: JSX.Element;
}

export interface AppRoleAssignedProps {
    appRoles: AppRole[];
    requiredRole: string;
    children: JSX.Element;
}

export const IsAppRolesAssigned = (appRoles: AppRole[], requiredRoles: string[]) => {
    if (requiredRoles && appRoles) {
        const isClaimed = appRoles.some((e) => {
            return requiredRoles.every((r) => {
                return (r === e.value && e.claimed)
            });
        });
        return isClaimed;
    } 
    return false;
}

export const IsAppRoleAssigned = (appRoles: AppRole[], requiredRole: string) => {
    return IsAppRolesAssigned(appRoles, [requiredRole]);
}

export const AppRolesAssigned = (props: AppRolesAssignedProps) => { 
    let { appRoles, requiredRoles, children } = props;
    if (IsAppRolesAssigned(appRoles, requiredRoles)) {
        return children;
    }
    return null;
}

export const AppRoleAssigned = (props: AppRoleAssignedProps) => { 
    let { appRoles, requiredRole, children } = props;
    if (IsAppRoleAssigned(appRoles, requiredRole)) {
        return children;
    }
    return null;
}

export const AppRolesNotAssigned = (props: AppRolesAssignedProps) => { 
    let { appRoles, requiredRoles, children } = props;
    if (!IsAppRolesAssigned(appRoles, requiredRoles)) {
        return children;
    }
    return null;
}

export const AppRoleNotAssigned = (props: AppRoleAssignedProps) => { 
    let { appRoles, requiredRole, children } = props;
    if (!IsAppRoleAssigned(appRoles, requiredRole)) {
        return children;
    }
    return null;
}

