import { Card, CardProps, ListGroup } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";


export const CardOrganisation = (props: CardProps) => {
    const app = useAppContext();

    const org = app.user?.organization;
    const otherDomains = org?.verifiedOtherDomains?.map((d) =>
        <small key={d.name}>{d.name}<br /></small>
    );

    return (
        <Card {...props} >
            <Card.Header>Organisation</Card.Header>
            <Card.Img variant="top" src={org?.bannerLogo} style={{ padding: '0.6rem' }} />
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <div className="me-auto">
                        <div className="fw-bold">Name</div>
                        {org?.displayName}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className="me-auto">
                        <div className="fw-bold">Primäre Domäne</div>
                        {org?.verifiedDefaultDomain?.name}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className="me-auto">
                        <div className="fw-bold">Weitere Domänen</div>
                        {otherDomains}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
}