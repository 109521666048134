import { Card, CardProps, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../App/AppContext";
import { IsAppRoleAssigned } from "../App/AppRolesAssigned";


export const CardTasks = (props: CardProps) => {
    const app = useAppContext();

    return (
        <Card {...props} >
            <Card.Header>Aufgaben</Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write')} >
                    <Link to="newaccount">Konto hinzufügen</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write')} >
                    <Link to="editaccount">Konto bearbeiten</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.delete')} >
                    <Link to="deleteaccount">Konto löschen</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.password.reset')} >
                    <Link to="changepwd">Kennwort ändern</Link>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
}