import Dexie from 'dexie';
import { epochTimeMs } from './dateTimeUtils';

export class LogDb extends Dexie {
    logEntries!: Dexie.Table<LogDbEntry, number>;

    constructor() {
        super("LogDb");

        this.version(2).stores({
            logEntries: '++id,epochMs'
        });
    }
}

export enum LogEntryType {
    Info,
    Success,
    Warning,
    Error
}

export interface LogEntry {
    epochMs: number;
    type: LogEntryType;
    topic: string,
    text: string;
}

export interface LogDbEntry extends LogEntry {
    id?: number;    // Primary key. (autoincremented)
}

export const saveLogEntryToDb = (le: LogEntry) => {
    const save = async (le: LogEntry) => {
        await logDb.logEntries.add(le);
    }
    save(le);
}

export const addLogEntry = (logType: LogEntryType, logTopic: string, logText: string) => {
    const le: LogEntry = { type: logType, topic: logTopic, text: logText, epochMs: epochTimeMs() };
    saveLogEntryToDb(le);
}

export const logDb = new LogDb();
