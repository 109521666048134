import { NullableOption, User } from '@microsoft/microsoft-graph-types';
import Dexie from 'dexie';
import { epochTimeMs } from './dateTimeUtils';

export class CreatedAccountDb extends Dexie {
    accounts!: Dexie.Table<AccountDbEntry, number>;

    constructor() {
        super("CreatedAccountDb");

        this.version(3).stores({
            accounts: '++id,createEpochMs,setUid'
        });
    }
}

export interface AccountEntry {
    createEpochMs: number;
    setUid: string;
    userId: string | undefined;
    userPrincipalName: NullableOption<string> | undefined;
    password: NullableOption<string> | undefined;
}

export interface AccountDbEntry extends AccountEntry {
    id?: number;
}

export const saveAccountToDb = (setUid: string, user: User) => {
    const save = async (user: User, setUid: string) => {
        const ae: AccountEntry = {
            createEpochMs: epochTimeMs(),
            setUid: setUid,
            userId: user.id,
            userPrincipalName: user.userPrincipalName,
            password: user.passwordProfile?.password
        };
        await createdAccountDb.accounts.add(ae);
    }
    save(user, setUid);
}

export const makeSetUid = (categoryName: string) => {
    // Creates a UID that identifies the accounts belonging together (created during one run)
    // Format: <epoch time in ms>_<categoryName>
    return `${epochTimeMs()}_${categoryName}`;
}

export const createdAccountDb = new CreatedAccountDb();
