import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProvideAppContext from "./AppContext";
import { Welcome } from "../Pages/Welcome";
import { Footer } from "../Navigation/Footer";
import { NavBar } from "../Navigation/NavBar";
import { ExportAccounts } from "../Pages/ExportAccounts";
import { ImportAccounts } from "../Pages/ImportAccounts";
import { UpdateAllSpecialProperties } from "../Pages/UpdateAllSpecialProperties";
import { NewAccount } from "../Pages/NewAccount";
import { DiffAccounts } from "../Pages/DiffAccounts";
import { DeleteAccounts } from "../Pages/DeleteAccounts";
import { EditAccount } from "../Pages/EditAccount";
import { DeleteAccount } from "../Pages/DeleteAccount";
import { ChangePassword } from "../Pages/ChangePassword";

type AppProps = {
    pca: IPublicClientApplication
};

export const App = ({ pca }: AppProps) => {

    return (
        <>
            <MsalProvider instance={pca}>
                <ProvideAppContext>
                    <BrowserRouter>
                        <NavBar />
                        <Footer />
                        <Routes>
                            <Route path="/" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <Welcome />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/export" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <ExportAccounts />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/import" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <ImportAccounts />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/newaccount" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <NewAccount />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/editaccount" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <EditAccount />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/deleteaccount" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <DeleteAccount />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/diff" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <DiffAccounts />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/bulkdel" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <DeleteAccounts />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/bulkupdspecial" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <UpdateAllSpecialProperties />
                                </MsalAuthenticationTemplate>
                            } />
                            <Route path="/changepwd" element={
                                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                                    <ChangePassword />
                                </MsalAuthenticationTemplate>
                            } />
                        </Routes>
                    </BrowserRouter>
                </ProvideAppContext>
            </MsalProvider>
        </>
    );
}