import { Accordion, Button, Card, CardProps } from "react-bootstrap";
import { ServiceHealthStatus } from "../AzAdAccess/serviceHealth";
import { config } from "../Config/config";
import { localDateTimeStringFromEpochMs } from "../Utils/dateTimeUtils";


export interface CardServiceHealthProps extends CardProps {
    serviceHealth: ServiceHealthStatus | undefined;
}

export const CardServiceHealth = (props: CardServiceHealthProps) => {
    let { serviceHealth, ...otherProps } = props;

    const serviceStatusOperational = serviceHealth?.operational?.map((s) =>
        <small key={s}>{s}<br /></small>
    );

    const serviceStatusDegraded = serviceHealth?.degraded?.map((s) =>
        <small key={s}>{s}<br /></small>
    );

    const serviceStatusInterrupted = serviceHealth?.interrupted?.map((s) =>
        <small key={s}>{s}<br /></small>
    );

    const serviceStatusOther = serviceHealth?.other?.map((s) =>
        <small key={s}>{s}<br /></small>
    );


    return (
        <Card {...otherProps}>
            <Card.Header>MS Dienststatus ({localDateTimeStringFromEpochMs(serviceHealth?.updateEpochMs)})</Card.Header>
            <Card.Body>
                <Accordion flush style={{ maxWidth: '20rem' }}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><i className="fa fa-circle" style={{ color: 'green' }} />
                            &nbsp;&nbsp; Fehlerfrei ({serviceHealth?.operational.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            {serviceStatusOperational}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><i className="fa fa-circle" style={{ color: 'orange' }} />
                            &nbsp;&nbsp; Eingeschränkt ({serviceHealth?.degraded.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            {serviceStatusDegraded}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><i className="fa fa-circle" style={{ color: 'red' }} />
                            &nbsp;&nbsp; Unterbrochen ({serviceHealth?.interrupted.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            {serviceStatusInterrupted}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><i className="fa fa-circle" style={{ color: "grey" }} />
                            &nbsp;&nbsp; Sonst ({serviceHealth?.other.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            {serviceStatusOther}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card.Body>
            <Card.Footer>
                <Button
                    variant="link"
                    onClick={() => window.open(config.adminPanelServiceHealth, '_blank', 'noopener,noreferrer')}>
                    Mehr Info
                </Button>
            </Card.Footer>
        </Card>
    );
}