export const config = {
    appId: '0346f2ec-4613-4889-9b80-2ed94ad4c8e5',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: 'https://login.microsoftonline.com/9d9913ea-7967-4690-bdb8-6a9d05a546e2',
    scopes: ['user.read'],
    adminPanelServiceHealth: 'https://admin.microsoft.com/#/servicehealth/',
    seriveHealthMon: ['Exchange Online', 'OneDrive for Business', 'Microsoft Teams', 'SharePoint Online', 'Microsoft 365 suite', 'Microsoft Intune'],
    csvDelimiter: ';',
    uidMaxLen: 16, // AzureAD limit
    deleteHeader: ['Konto'],
    deleteSelect: ['userPrincipalName'],
    categories: [
        {
            name: 'Lehrer:innen',
            exportName: 'LehrerInnen',
            exportHeader: ['Konto', 'Typ', 'Familienname', 'Vorname', 'Anzeigename', 'UID'],
            exportSelect: ['userPrincipalName', 'jobTitle', 'surname', 'givenName', 'displayName', 'employeeId'],
            importHeader: ['Konto', 'Familienname', 'Vorname', 'Anzeigename', 'UID'],
            importSelect: ['userPrincipalName', 'surname', 'givenName', 'displayName', 'employeeId'],
            passwordHeader: 'Kennwort',
            mainGroup: {
                name: 'Lehrer',
                id: '40e9a6de-6dad-4cbf-bcf7-182494386df6'
            },
            licenseGroup: {
                // name: 'Microsoft 365 A3 for faculty License',
                // id: '9901e638-5aae-490e-8aaa-a9fb4c4cc795'
                name: 'Office 365 A1 Plus Faculty License',
                id: '7bedcc00-0c69-485c-8634-bd2f5231ab6f'
            },
            upn: {
                domain: 'brg-pichelmayergasse.at',
                nameTemplate: 'givenName.substring(0, 1) + surname'
            },
            passwordProfile: {
                forceChangePasswordNextSignIn: true,
                forceChangePasswordNextSignInWithMfa: false
            },
            passwordLength: 8,
            specialProperties: [
                {
                    jobTitle: 'Lehrer:in'
                }
            ]
        },
        {
            name: 'Schüler:innen',
            exportName: 'SchülerInnen',
            exportHeader: ['Konto', 'Typ', 'Familienname', 'Vorname', 'Anzeigename', 'Klasse', 'UID'],
            exportSelect: ['userPrincipalName', 'jobTitle', 'surname', 'givenName', 'displayName', 'department', 'employeeId'],
            importHeader: ['Konto', 'Familienname', 'Vorname', 'Anzeigename', 'Klasse', 'UID'],
            importSelect: ['userPrincipalName', 'surname', 'givenName', 'displayName', 'department', 'employeeId'],
            passwordHeader: 'Kennwort',
            mainGroup: {
                name: 'Schueler',
                id: '94dbc2e0-023f-4a67-bb5e-4a29be793993'
            },
            licenseGroup: {
                name: 'Microsoft 365 A3 for students use benefit License',
                id: 'd5e30bde-5248-45e5-b3bd-c7bb21e42032'
            },
            upn: {
                domain: 'brg-pichelmayergasse.at',
                nameTemplate: 'givenName.substring(0, 1) + surname'
            },
            passwordProfile: {
                forceChangePasswordNextSignIn: false,
                forceChangePasswordNextSignInWithMfa: false
            },
            passwordLength: 8,
            specialProperties: [
                {
                    jobTitle: 'Schüler:in'
                }
            ]
        }
    ]
};

export const getCategory = (name: string) => {
    const cat = config.categories.find((c) => c.name === name);
    return cat;
}

export const getCategoryNames = (): string[] => {
    const cN = config.categories.map((c) => {
        return c.name;
    });
    return cN;
}

export const getCategoryNameBySpecialProps = (specialPropertyName: string, specialPropertyValue: string) => {
    let cN = '';
    for (const key in config.categories) {
        const c = config.categories[key];
        for (const sp of c.specialProperties) {
            const idx = specialPropertyName as keyof typeof sp;
            if (sp[idx] === specialPropertyValue) {
                cN = c.name;
                break;
            }
        }
    }
    return cN;
}

export const getCsvHeaderNameForExportSelect = (cat: typeof config.categories[0], select: string) => {
    const idx = cat.exportSelect.indexOf(select);
    if (idx >= 0)
        return cat.exportHeader[idx];
}

export const getCsvHeaderNameForExportSelectN = (categoryName: string, select: string) => {
    const cat = getCategory(categoryName);
    if (cat)
        return getCsvHeaderNameForExportSelect(cat, select);
}

export const getCsvHeaderNameForDeleteSelect = (select: string) => {
    const idx = config.deleteSelect.indexOf(select);
    if (idx >= 0) {
        return config.deleteHeader[idx];
    }
}

export const getCsvHeaderNameForImportSelect = (cat: typeof config.categories[0], select: string) => {
    const idx = cat.importSelect.indexOf(select);
    if (idx >= 0)
        return cat.importHeader[idx];
}

export const getCsvHeaderNameForImportSelectN = (categoryName: string, select: string) => {
    const cat = getCategory(categoryName);
    if (cat)
        return getCsvHeaderNameForImportSelect(cat, select);
}

export const getSpecialProps = (cat: typeof config.categories[0]): {[key: string]: string}[] => {
    return cat.specialProperties;
}

export const getSpecialPropsN = (categoryName: string) => {
    const cat = getCategory(categoryName);
    if (cat) 
        return getSpecialProps(cat);
}

export const getJobTitleFromCategory = (cat: typeof config.categories[0]) => {
    const specialProps = getSpecialProps(cat);
    for (const sp of specialProps) {
        const jt = sp['jobTitle'];
        if (jt) return jt;
    }
}