import { useEffect, useState } from "react";
import { Alert, ListGroup, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { useGetAccounts } from "../AzAdAccess/accounts";
import { config, getCategory, getCategoryNames } from "../Config/config";
import { ButtonProgress } from "../UIElements/ButtonProgress";
import { LogDisp } from "../UIElements/LogDisp";
import { Selector } from "../UIElements/Selector";
import { saveToCsvFile } from "../Utils/csvUtils";
import { epochTimeMs, fileNameWithDate, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";

export const ExportAccounts = () => {

    const logTopic = 'Konten exportieren';
    const app = useAppContext();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filename, setFilename] = useState('');
    const cat = getCategory(selectedCategory);
    const { start, accounts, isLoading, maxCount, count, isDone } = useGetAccounts(app.authProvider!);
    const [logDispStartTime, setLogDispStartTime] = useState(0);

    const progress = (): number => {
        if (maxCount > 0) {
            return Math.round(100 * count / maxCount)
        }
        return 0;
    }

    useEffect(() => {
        if (accounts.length > 0) {
            const fn = fileNameWithDate(cat?.exportName!, 'csv');
            saveToCsvFile(fn, accounts, config.csvDelimiter);
            setFilename(fn);
            addLogEntry(LogEntryType.Success, logTopic, `${count} Konten wurden exportiert: ${fn}`);
            addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    return (
        <div className="page-margin page-maxwidth">
            <h3>Konten exportieren</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.read" >
                <ListGroup>
                    <ListGroup.Item className="border-0" disabled={isLoading}>
                        <Selector title="Kategroie auswählen ..." items={getCategoryNames()} onItemChanged={e => {
                            setSelectedCategory(e.currentTarget.value);
                        }} />
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0}>
                        <ButtonProgress
                            buttonText={"Herunterladen ..."}
                            isInProgress={isLoading}
                            isDoneOk={isDone}
                            disabled={isLoading}
                            onClick={e => {
                                addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
                                start(selectedCategory);
                            }} />
                        <p />
                        <div hidden={!isLoading && !isDone} >
                            Konten werden exportiert ...
                            <ProgressBar now={progress()} label={`${progress()}%`} />
                            <p />
                        </div>

                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.read">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
        </div>
    );
}