import classNames from "classnames/dedupe";
import React, { useEffect, useRef, useState } from "react";
import { Button, CloseButton, CloseButtonProps, FormControl } from "react-bootstrap";

export interface LabelProps extends CloseButtonProps {
    name: string
}

export const Tag = (props: LabelProps) => {
    const { name, ...otherProps } = props;
    return (
        <div className="label">
            <CloseButton style={{ maxHeight: "3px", maxWidth: "3px" }} {...otherProps} />
            &nbsp;
            {name}
        </div>
    );
}

export interface LabelGroupProps {
    title?: string;
    children?: React.ReactNode;
    onAdd?: () => void;
    validationMinNumTags?: number;
}

export const TagGroup = (props: LabelGroupProps) => {
    const { title, children, onAdd, validationMinNumTags, ...otherProps } = props;
    const dummyFormControl = useRef<HTMLInputElement | null>(null);
    const isInvalid = useRef(false);
    const isWasValidated = useRef(false);

    const [classNameList, setClassNameList] = useState('label-group-container label-group-text form-control');

    // Validation
    //
    const countTags = children ? (children as []).length : 0;
    if (dummyFormControl && dummyFormControl.current) {
        // This not visible control is used to spport html-5 form validation
        // Setting any value will make the validation pass
        //
        isInvalid.current = true;
        dummyFormControl.current.defaultValue = '';
        if (validationMinNumTags) {
            if (countTags >= validationMinNumTags) {
                dummyFormControl.current.defaultValue = 'ok';
                isInvalid.current = false;
            }
        } else {
            dummyFormControl.current.defaultValue = 'ok';
            isInvalid.current = false;
        }
    }

    useEffect(() => {
        // Runs after DOM update (which is required to capture the was-validated class)
        //
        if (dummyFormControl && dummyFormControl.current) {
            // if form has class was-validated -> form validation is requested.
            isWasValidated.current = dummyFormControl.current.form?.classList.contains('was-validated') || false;
        }

        let classNameListNew = classNames(classNameList, { 'is-valid': false, 'is-invalid': false });
        if (isInvalid.current && isWasValidated.current) {
            classNameListNew = classNames(classNameList, { 'is-valid': false, 'is-invalid': true });
        } else if (!isInvalid.current && isWasValidated.current) {
            classNameListNew = classNames(classNameList, { 'is-valid': true, 'is-invalid': false });
        }

        if (classNameListNew !== classNameList) {
            setClassNameList(classNameListNew);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children, validationMinNumTags]);

    return (
        <div className={classNameList} {...otherProps}>
            {title} &nbsp;
            {onAdd && <Button variant="link" size="sm" style={{ marginTop: "-2px", padding: 0, fontSize: "small", float: "right" }}
                onClick={(e) => {
                    onAdd && onAdd();
                }}>
                hinzufügen
            </Button>}
            <div className="label-group">
                {children}
            </div>
            <FormControl ref={dummyFormControl} required style={{ display: "none" }} />
        </div>
    );
}
