import { Card, CardProps } from "react-bootstrap";


export interface CardCountMainGrpsProps extends CardProps {
    cmgr: { name: string, count: number | undefined }[];
}

export const CardCountMainGrps = (props: CardCountMainGrpsProps) => {
    let { cmgr, ...otherProps } = props;
    
    const cardBodies = cmgr.map((c) =>
        <Card.Body key={c.name}>
            <Card.Title>{c.count === 0 ? '--' : c.count}</Card.Title>
            <Card.Subtitle>{c.name}</Card.Subtitle>
        </Card.Body>
    );

    return (
        <Card {...otherProps}>
            <Card.Header>Kategorien (Konten)</Card.Header>
            {cardBodies}
        </Card>
    );
}