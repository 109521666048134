import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { PasswordProfile } from "@microsoft/microsoft-graph-types";
import { addMemberToGroup, createUser, getUser, isUserExists } from "../AzAdAccess/graphService";
import { getCsvVal } from "./csvUtils";
import { generatePassword } from "./gernatePassword";
import { LogEntryType } from "./logDb";

export const makeCleanUserName = (name: string) => {
    let cleanName = '';
    for (let c of name) {
        if (c === 'ä') {
            cleanName += 'ae';
        } else if (c === 'ö') {
            cleanName += 'oe';
        } else if (c === 'ü') {
            cleanName += 'ue';
        } else if (c === 'ß') {
            cleanName += 'ss';
        } else if (c === 'Ä') {
            cleanName += 'AE';
        } else if (c === 'Ö') {
            cleanName += 'OE';
        } else if (c === 'Ü') {
            cleanName += 'UE';
        } else {
            if ((c >= 'A' && c <= 'Z') || (c >= 'a' && c <= 'z') || (c >= '0' && c <= '9')) {
                cleanName += c;
            }
        }
    }
    return cleanName;
}

export const makeUpn = (cat: any, givenName?: string, surname?: string, displayName?: string) => {
    if (givenName && surname) {
        const fmt = cat.upn.nameTemplate;
        // eslint-disable-next-line no-new-func
        return makeCleanUserName((Function('givenName', 'surname', 'return(' + fmt + ')')(givenName, surname)).toLowerCase()) + '@' + cat.upn.domain;
    }
    if (displayName) {
        return displayName.toLowerCase().replace(/\s/g, '');
    }
    if (surname) {
        return surname.toLowerCase().replace(/\s/g, '');
    }
    if (givenName) {
        return givenName.toLowerCase().replace(/\s/g, '');
    }
}

export const checkUpn = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, cat: any, upn: string) => {
    let count = 0;
    const upnNamePart = upn.split('@')[0];
    let upnDomainPart = upn.split('@')[1];

    if (!upnDomainPart) {
        upnDomainPart = cat.upn.domain;
    }

    let newUpn = `${upnNamePart}@${upnDomainPart}`;
    let isUe = false;
    do {
        try {
            isUe = await isUserExists(authProvider, 'userPrincipalName', newUpn);
            if (isUe) {
                const newUpnNamePart = `${upnNamePart}${++count}`;
                newUpn = `${newUpnNamePart}@${upnDomainPart}`;
            }
        } catch { }
    } while (isUe);
    return newUpn;
}

export const makeDisplayName = (givenName?: string, surname?: string) => {
    if (givenName && surname) {
        return `${surname.toUpperCase()} ${givenName}`;
    }
    if (givenName) {
        return givenName;
    }
    if (surname) {
        return surname.toUpperCase();
    }
    return '';
}

export const createUserAccount = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, cat: any, row: Record<string, string>,
    assignedFields: Record<string, string> | null | undefined, groups: {name: string, id: string}[] | null | undefined, 
    logTopic: string, addLogEntry: (lent: LogEntryType, ltopic: string, txt: string) => void) => {

    // Create a new user. If the user already exists in Azure AD, no action is taken.
    // The existence of a user is determined by the upn and/or the employeeID.
    // The properties surname and given name are required.
    //

    const upnCsv = assignedFields ? getCsvVal(cat, assignedFields, 'userPrincipalName', row) : row['userPrincipalName'];
    const employeeIdCsv = assignedFields ? getCsvVal(cat, assignedFields, 'employeeId', row) : row['employeeId'];
    const givenNameCsv = assignedFields ? getCsvVal(cat, assignedFields, 'givenName', row) : row['givenName'];
    const surnameCsv = assignedFields ? getCsvVal(cat, assignedFields, 'surname', row) : row['surname'];
    const displayNameCsv = assignedFields ? getCsvVal(cat, assignedFields, 'displayName', row) : row['displayName'];
    const departmentCsv = assignedFields ? getCsvVal(cat, assignedFields, 'department', row) : row['department'];

    let user = undefined;

    if (givenNameCsv && surnameCsv) {
        let isAzUpnExisitng = false;
        if (upnCsv) {
            const ue = await isUserExists(authProvider, 'userPrincipalName', upnCsv);
            if (ue) isAzUpnExisitng = true;
        }
        let isAzEmployeeIdExisting = false;
        if (employeeIdCsv) {
            const ue = await isUserExists(authProvider, 'employeeId', employeeIdCsv);
            if (ue) isAzEmployeeIdExisting = true;
        }

        if (isAzEmployeeIdExisting || isAzUpnExisitng) {
            let reason = 'UID vorhaden';
            if (isAzUpnExisitng) reason = 'Kontoname vorhanden';
            if (isAzEmployeeIdExisting) {}
            addLogEntry(LogEntryType.Warning, logTopic,
                `Das Konto <em>${JSON.stringify(row, null, 1)}</em> existiert bereits: <b>${reason}</b>.`);
            return { res: false, user: user };
        }

        let pwdProfile = cat.passwordProfile;
        pwdProfile['password'] = generatePassword(cat.passwordLength);
        let userProps = {
            accountEnabled: true,
            givenName: givenNameCsv,
            surname: surnameCsv.toUpperCase(),
            displayName: displayNameCsv ? displayNameCsv : makeDisplayName(givenNameCsv, surnameCsv),
            passwordProfile: pwdProfile,
            employeeId: employeeIdCsv ? employeeIdCsv : null,
            userPrincipalName: '',
            mailNickname: '',
            department: departmentCsv ? departmentCsv.toUpperCase() : null
        }
        // add special props
        for (const sp of cat.specialProperties) {
            userProps = { ...userProps, ...sp };
        }
        if (upnCsv) {
            userProps.userPrincipalName = upnCsv;
            userProps.mailNickname = upnCsv.split('@')[0];
        } else {
            // Create the new user with or without employeeId and automatically create the upn
            userProps.userPrincipalName = makeUpn(cat, givenNameCsv, surnameCsv, displayNameCsv)!;
            userProps.mailNickname = userProps.userPrincipalName.split('@')[0];
        }

        let error = undefined;
        do {
            // Check if the upn already exists and propose a new one
            userProps.userPrincipalName = await checkUpn(authProvider, cat, userProps.userPrincipalName); 
            userProps.mailNickname = userProps.userPrincipalName.split('@')[0];
            error = await createUser(authProvider, userProps);
            if (error && error.code === 'ObjectConflict' && error.target === 'userPrincipalName') {
                // If a user with the same name has been created previously, it may take some time for it to become visible. 
                await new Promise(r => setTimeout(r, 20000)); // sleep 20s
            }
        } while (error && error.code === 'ObjectConflict' && error.target === 'userPrincipalName');

        if (error) {
            console.log(error.message);
            addLogEntry(LogEntryType.Warning, logTopic,
                `Das Konto <em>${JSON.stringify(userProps, null, 1)}</em> konnte nicht angelegt werden.`);
            return { res: false, user: user };
        }

        user = undefined;
        let retryCount = 0;
        while (!user && retryCount < 50) {
            user = await getUser(authProvider, userProps.userPrincipalName, cat.exportSelect.join(',') + ',id');
            if (!user) {
                console.log("Retrying to get user object after creation ...");
                await new Promise(r => setTimeout(r, 10000)); // sleep 10s
                ++retryCount;
            }
        }
        if (user && user.id) {
            const pwdProfile: PasswordProfile = {
                password: userProps.passwordProfile.password
            };
            user.passwordProfile = pwdProfile;
            const accData = `${user.userPrincipalName} (${user.displayName})`;
            addLogEntry(LogEntryType.Success, logTopic, `Das Konto <em>${accData}</em> wurde erfolgreich angelegt.`);

            if (groups) {
                for (const grp of groups) {
                    const rg = await addMemberToGroup(authProvider, user.id, grp.id);
                    if (!rg) {
                        addLogEntry(LogEntryType.Warning, logTopic,
                            `Die Zuweisung des Kontos ${accData} zur Gruppe ${grp.name} ist fehlgeschlagen`);
                    }
                }
            }
        } else {
            addLogEntry(LogEntryType.Warning, logTopic,
                `Das Konto <em>${JSON.stringify(userProps, null, 1)}</em> konnte nicht erfolgreich angelegt werden.`);
            return { res: false, user: user };
        }
        return { res: true, user: user };
    }

    addLogEntry(LogEntryType.Warning, logTopic,
        `Spalte <b>${givenNameCsv}</b> oder <b>${surnameCsv}</b> enthält in dieser Zeile keinen Wert: <em>${JSON.stringify(row, null, 1)}</em>`);
    return { res: false, user: user };
}