import Papa from "papaparse";
import { getCsvHeaderNameForImportSelect } from "../Config/config";
import { saveStringToFile } from "./fileUtils";

export const makeCsv = (data: any[], delimiter: string) => {
    const csv = Papa.unparse(data , {
        delimiter: delimiter
    });
    return csv;
}

export const saveToCsvFile = (filename: string, data: any[], delimiter: string) => {
    const csv = makeCsv(data, delimiter);
    saveStringToFile(csv, filename, 'text/csv');
}

export const readCsvFile = (file: File, csv: (res: Papa.ParseResult<any>) => void): void => {
    Papa.parse(file, {
        complete: (res) => csv(res),
        header: true,
        skipEmptyLines: true
    });
}

export const getCsvVal = (cat: any, assignedFields: Record<string, string>, select: string, row: Record<string, string>) => {
    const header = getCsvHeaderNameForImportSelect(cat!, select);
    const csvHeader = assignedFields[header!];
    return row[csvHeader];
}