import { LogEntryType } from "../Utils/logDb"

export const getLogEntryTypeIcon = (logType: LogEntryType) => {
    if (logType === LogEntryType.Error) {
        return <span className="fas fa-exclamation-circle text-danger"/>
    }
    if (logType === LogEntryType.Warning) {
        return <span className="fas fa-exclamation-circle text-warning"/>
    }
    if (logType === LogEntryType.Success) {
        return <span className="fas fa-check-circle text-success"/>
    }
    return <span className="fas fa-info-circle text-primary"/>
}