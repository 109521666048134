import { Offcanvas, OffcanvasProps } from "react-bootstrap";
import { CreatedUserAccountDetails, CreatedUserAccountDetailsProps } from "../UIElements/CreatedUserAccountDetails";

export interface OcCreatedUserAccountDetailsProps extends CreatedUserAccountDetailsProps, OffcanvasProps { }

export const OcCreatedUserAccountDetails = (props: OcCreatedUserAccountDetailsProps) => {
    const { category, user, groups, ...otherProps } = props; 
    return (
        <Offcanvas {...otherProps}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Neues Konto</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <CreatedUserAccountDetails category={category} user={user} groups={groups} />
            </Offcanvas.Body>
        </Offcanvas>
    );
}