import Navbar from 'react-bootstrap/Navbar';
import { Nav, NavDropdown } from 'react-bootstrap';
import logo from './logo.svg';
import { useAppContext } from '../App/AppContext';
import { useNavigate } from "react-router-dom";
import { OcLogView } from '../Pages/OcLogView';
import { useState } from 'react';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import { IsAppRoleAssigned } from '../App/AppRolesAssigned';


export const NavBar = () => {
    const app = useAppContext();
    const user = app.user || { displayName: '?' };
    const navigate = useNavigate();
    const [showLog, setShowLog] = useState(false);

    const UserMenu = () => {
        const userMenuTitle =
            user.photoUrl ?
                <span>
                    <img
                        src={user.photoUrl}
                        className="photo-avatar rounded-circle"
                        alt=''
                    />
                    &nbsp;&nbsp;{user.displayName}
                </span>
                :
                <>
                    <span className="fas fa-user-circle" /> &nbsp;&nbsp;{user.displayName}
                </>
        return (
            <NavDropdown title={userMenuTitle} align="end" id="dropdown-menu-align-left" menuVariant="dark">
                <NavDropdown.Item onClick={app.signOut}>Abmelden</NavDropdown.Item>
            </NavDropdown>
        );
    }

    return (
        <>
            <Navbar expand="sm" fixed="top" bg="dark" variant="dark">
                <Navbar.Brand className="ms-3">
                    <img
                        src={logo}
                        className="logo-navbar img-fluid d-inline-block align-top"
                        alt=''
                    />
                    &nbsp;
                    Az ADUM
                </Navbar.Brand>
                <NavbarToggle />
                <Navbar.Collapse className="ms-3">
                    <Nav>
                        <Nav.Link onClick={(e) => { navigate('/') }}>Home</Nav.Link>
                        <Nav.Link onClick={(e) => { setShowLog(true) }}>Log</Nav.Link>
                        <Nav>
                            <NavDropdown title="Aufgaben" id="dropdown-menu-align-left" menuVariant="dark">
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write')} 
                                    onClick={(e) => { navigate('/newaccount') }}>Konto hinzufügen</NavDropdown.Item>
                                <NavDropdown.Item  hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write')} 
                                    onClick={(e) => { navigate('/editaccount') }}>Konto bearbeiten</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.delete')} 
                                    onClick={(e) => { navigate('/deleteaccount') }}>Konto löschen</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.password.reset')} 
                                    onClick={(e) => { navigate('/changepwd') }}>Kennwort ändern</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write.bulk')} 
                                    onClick={(e) => { navigate('/import') }}>Konten importieren</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.read')} 
                                    onClick={(e) => { navigate('/export') }}>Konten exportieren</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.read')} 
                                    onClick={(e) => { navigate('/diff') }}>Konten abgleichen</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.delete.bulk')} 
                                    onClick={(e) => { navigate('/bulkdel') }}>Konten löschen</NavDropdown.Item>
                                <NavDropdown.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write.bulk')} 
                                    onClick={(e) => { navigate('/bulkupdspecial') }}>Spezielle Eigenschaften aktualisieren</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end me-3 ms-3">
                    <Nav>
                        <UserMenu />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <OcLogView show={showLog} placement="bottom" onHide={() => setShowLog(false)} />
        </>
    );
}
