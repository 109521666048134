import { useLiveQuery } from "dexie-react-hooks";
import { Table } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";
import parse from 'html-react-parser';
import { logDb } from "../Utils/logDb";
import { getLogEntryTypeIcon } from "./LogEntryTypeIcon";
import { localTimeStringFromEpochMs } from "../Utils/dateTimeUtils";

export interface LogDispProps {
    height?: number | string;
    startTime?: number; // show log entries younger then this epoch time (in ms).
}

export const LogDisp = (props: LogDispProps) => {
    const start = props.startTime ? props.startTime : 0;    

    const logEntries = useLiveQuery(async () => {
        return await logDb.logEntries
            .where('epochMs').above(start)
            .reverse()
            .toArray()
    }, [start]);

    const rows = () => {
        return logEntries?.map((ldbe) => {
            return (
                <tr key={ldbe.id}>
                    <td><span className="nowrap">{localTimeStringFromEpochMs(ldbe.epochMs)}</span></td>
                    <td>{getLogEntryTypeIcon(ldbe.type)}</td>
                    <td>{parse(sanitizeHtml(ldbe.text))}</td>
                </tr>
            );
        });
    }

    return (
        <div style={{ overflowY: "auto", ...props }} >
            <Table size="sm" className="oc-log-table" responsive="sm">
                <tbody >
                    {rows()}
                </tbody>
            </Table>
        </div>
    );
}