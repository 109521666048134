import { User } from "@microsoft/microsoft-graph-types";
import { getCsvHeaderNameForImportSelect } from "../Config/config";
import { getCsvVal } from "./csvUtils";
import { LogEntryType } from "./logDb";

export const accountDiff = (
    cat: any, accounts: User[], csvData: any[],
    assignedFields: Record<string, string>, progress: (done: boolean, maxCount: number, count: number) => void,
    logTopic: string, addLogEntry: (lent: LogEntryType, ltopic: string, txt: string) => void) => {

    const newAccounts: Record<string, string>[] = [];
    const changedAccounts: Record<string, string>[] = [];
    const sameAccounts: Record<string, string>[] = [];
    const errorAccounts: Record<string, string>[] = [];
    
    const maxCount = csvData.length + accounts.length;
    let count = 0;
    for (const row of csvData) {
        const upnCsv = getCsvVal(cat, assignedFields, 'userPrincipalName', row);
        const employeeIdCsv = getCsvVal(cat, assignedFields, 'employeeId', row);
        const givenNameCsv = getCsvVal(cat, assignedFields, 'givenName', row);
        const surnameCsv = getCsvVal(cat, assignedFields, 'surname', row);
        const displayNameCsv = getCsvVal(cat, assignedFields, 'displayName', row);
        const departmentCsv = getCsvVal(cat, assignedFields, 'department', row);
        let azUser: User | undefined;

        progress(false, maxCount, ++count);
    
        const rowToSave: Record<string, string> = { };
        if (isStrVal(upnCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'userPrincipalName')!] = upnCsv;
        if (isStrVal(employeeIdCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'employeeId')!] = employeeIdCsv;
        if (isStrVal(givenNameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'givenName')!] = givenNameCsv;
        if (isStrVal(surnameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'surname')!] = surnameCsv;
        if (isStrVal(displayNameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'displayName')!] = displayNameCsv;
        if (isStrVal(departmentCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'department')!] = departmentCsv;

        // Check if account is new
        //
        if (upnCsv) {
            const users = accounts.filter(a => a.userPrincipalName === upnCsv)
            if (users.length === 0) {
                newAccounts.push(rowToSave);
            } else {
                if (users.length > 1) {
                    addLogEntry(LogEntryType.Error, logTopic,
                        `Der Kontoname <em>${upnCsv}</em> wird bereits von mehreren Konten (${users.map(u => u.userPrincipalName).join(', ')}) verwendet.`);
                    errorAccounts.push(rowToSave);
                } else {
                    azUser = users[0];
                }
            }
        } else if (employeeIdCsv) {
            const users = accounts.filter(a => a.employeeId === employeeIdCsv)
            if (users.length === 0) {
                newAccounts.push(rowToSave);
            } else {
                if (users.length > 1) {
                    addLogEntry(LogEntryType.Error, logTopic,
                        `Die UID <em>${employeeIdCsv}</em> wird bereits von mehreren Konten (${users.map(u => u.userPrincipalName).join(', ')}) verwendet.`);
                    errorAccounts.push(rowToSave);
                } else {
                    azUser = users[0];
                }
            }
        }

        // Account already exists. Check if something has changed
        //
        if (azUser) {
            if (!isStrVal(upnCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'userPrincipalName')!] = azUser.userPrincipalName!;
            if (!isStrVal(employeeIdCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'employeeId')!] = azUser.employeeId!;
            if (!isStrVal(givenNameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'givenName')!] = azUser.givenName!;
            if (!isStrVal(surnameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'surname')!] = azUser.surname!;
            if (!isStrVal(displayNameCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'displayName')!] = azUser.displayName!;
            if (!isStrVal(departmentCsv)) rowToSave[getCsvHeaderNameForImportSelect(cat, 'department')!] = azUser.department!;

            let isDifferent = false;
            isStrVal(employeeIdCsv) ? isDifferent = diff(employeeIdCsv, azUser.employeeId) : isDifferent = false;
            isDifferent = isDifferent || isStrVal(givenNameCsv) ? diff(givenNameCsv, azUser.givenName) : false;
            isDifferent = isDifferent || isStrVal(surnameCsv) ? diff(surnameCsv, azUser.surname) : false;
            isDifferent = isDifferent || isStrVal(displayNameCsv) ? diff(displayNameCsv, azUser.displayName) : false;
            isDifferent = isDifferent || isStrVal(departmentCsv) ? diff(departmentCsv.toUpperCase(), azUser.department?.toUpperCase()) : false;

            if (isDifferent) {
                changedAccounts.push(rowToSave);
            } else {
                sameAccounts.push(rowToSave);
            }
        }
    }

    // Get all the accounts that can be deleted
    //
    const accDel: User[] = []; 
    for (const acc of accounts) {
        progress(false, maxCount, ++count);

        const isInChanged = isInAccounts(changedAccounts, acc, cat);
        const isInSame = isInAccounts(sameAccounts, acc, cat);
        const isInError = isInAccounts(errorAccounts, acc, cat);
        if (!(isInChanged || isInSame || isInError)) {
            accDel.push(acc);
        }
    }

    // Change the header
    //
    const deleteAccounts = accDel.map((a) => {
        let drow: Record<string, string> = {};
        for (const sel of cat.importSelect) {
            const selIdx = sel as keyof typeof a;
            const v = a[selIdx] as string;
            const h = getCsvHeaderNameForImportSelect(cat!, sel);
            if (h && v) {
                drow[h] = v;
            }
        }
        return drow;
    });
   
    progress(true, maxCount, count);
    addLogEntry(errorAccounts.length === 0 ? LogEntryType.Success : LogEntryType.Warning, logTopic,
        `Neue Konten: ${newAccounts.length},<br/>\
        Geänderte Konten: ${changedAccounts.length},<br/>\
        Zu löschende Konten: ${deleteAccounts.length},<br/>\
        Konten mit Fehlern: ${errorAccounts.length},<br/>\
        Unveränderte Konten: ${sameAccounts.length}`);

    return { newAccounts, changedAccounts, sameAccounts, errorAccounts, deleteAccounts }
}

const diff = (str1: string | null | undefined, str2: string | null | undefined) => {
    if (!str1) str1 = '';
    if (!str2) str2 = '';
    return !(str1.trim() === str2.trim());
}

const isInAccounts = (accounts: Record<string, string>[], acc: User, cat: any) => {
    const isInAccs = accounts.some((a) => {
        const upnCsv = a[getCsvHeaderNameForImportSelect(cat, 'userPrincipalName')!];
        const employeeIdCsv = a[getCsvHeaderNameForImportSelect(cat, 'employeeId')!];
        if (upnCsv) {
            return !diff(upnCsv, acc.userPrincipalName);
        } else if (employeeIdCsv) {
            return !diff(employeeIdCsv, acc.employeeId);
        }
        return true; // if neither the employeeId nor the upn is available (=> do not add to delete list) 
    });
    return isInAccs;
}

const isStrVal = (str: string | null | undefined) => {
    if (!str) return false;
    if (str.trim().length > 0) return true;
    return false;
}