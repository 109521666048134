import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { Alert, Button, Form, ListGroup } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { config, getCategory, getCategoryNames, getCsvHeaderNameForExportSelect } from "../Config/config";
import { BasicUserData, BasicUserDataValues } from "../UIElements/BasicUserData";
import { ButtonProgress } from "../UIElements/ButtonProgress";
import { LogDisp } from "../UIElements/LogDisp";
import { Selector } from "../UIElements/Selector";
import { makeSetUid, saveAccountToDb } from "../Utils/createdAccountDb";
import { createUserAccount } from "../Utils/createUser";
import { saveToCsvFile } from "../Utils/csvUtils";
import { epochTimeMs, fileNameWithDate, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";
import { OcCreatedUserAccountDetails } from "./OcCreatedUserAccountDetails";


export const NewAccount = () => {

    const app = useAppContext();
    const [selectedCategory, setSelectedCategory] = useState('');
    const [basicUserDataValues, setBasicUserDataValues] = useState<BasicUserDataValues>();
    const [logDispStartTime, setLogDispStartTime] = useState(0);
    const [createAccountInProgress, setCreateAccountInProgress] = useState(false);
    const [validated, setValidated] = useState(false);
    const [createdUserAccount, setCreatedUserAccount] = useState<User>();
    const [assignedGroups, setAssignedGroups] = useState<{ name: string, id: string }[]>();
    const [accountSuccessfullyCreated, setAccountSuccessfullyCreated] = useState(false);
    const [showCreatedAccount, setShowCreatedAccount] = useState(false);

    const logTopic = 'Neues Konto';
    const cat = getCategory(selectedCategory);

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
        try {
            setAccountSuccessfullyCreated(false);
            const form = e.currentTarget;
            e.preventDefault();

            if (form.checkValidity() === false) {
                e.stopPropagation();
            } else {
                const create = async (budv: BasicUserDataValues) => {
                    setCreateAccountInProgress(true);
                    const setUid = makeSetUid(selectedCategory);

                    const row: Record<string, string> = {};
                    for (const sel of cat!.importSelect) {
                        const budvIdx = sel as keyof typeof budv;
                        const budvVal = budv[budvIdx];
                        if (budvVal && typeof budvVal === 'string') {
                            // all values but the groups
                            row[sel] = budvVal;
                        }
                    }
                    const createRes = await createUserAccount(app.authProvider!, cat, row, null, budv.groups, logTopic, addLogEntry);
                    if (createRes.res) {
                        setAccountSuccessfullyCreated(true);
                        setCreatedUserAccount(createRes.user!);
                        setAssignedGroups(budv.groups);
                        saveAccountToDb(setUid, createRes.user!);
                        setShowCreatedAccount(true);

                        const rowToSave: Record<string, string> = {};
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'userPrincipalName')!] = createRes.user?.userPrincipalName || '';
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'jobTitle')!] = createRes.user?.jobTitle || '';
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'surname')!] = createRes.user?.surname || '';
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'givenName')!] = createRes.user?.givenName || '';
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'displayName')!] = createRes.user?.displayName || '';
                        rowToSave[getCsvHeaderNameForExportSelect(cat!, 'employeeId')!] = createRes.user?.employeeId || '';
                        if (getCsvHeaderNameForExportSelect(cat!, 'department')) {
                            rowToSave[getCsvHeaderNameForExportSelect(cat!, 'department')!] = createRes.user?.department || '';
                        }
                        rowToSave[cat!.passwordHeader] = createRes.user?.passwordProfile?.password || '';
                        for (let idx = 0; idx < budv!.groups!.length; ++idx) {
                            const grp = budv!.groups![idx];
                            rowToSave[`Gruppe ${idx + 1}`] = grp.name;
                        }

                        const fn = fileNameWithDate(`Neue_${cat?.exportName!}`, 'csv');
                        saveToCsvFile(fn, [rowToSave], config.csvDelimiter);
                        addLogEntry(LogEntryType.Info, logTopic, `Das neu angelegten Konten wurden gespeichert: ${fn}`);
                    } else {
                        setAccountSuccessfullyCreated(false);
                    }
                    setCreateAccountInProgress(false);
                    setValidated(false);
                    addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
                }
                if (basicUserDataValues) {
                    create(basicUserDataValues);
                }
            }
            setValidated(true);
        } catch { }
    }

    return (
        <div className="page-margin page-maxwidth">
            <h3>Konto hinzufügen</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.write" >
                <ListGroup>
                    <ListGroup.Item className="border-0" >
                        <Selector title="Kategorie auswählen ..." items={getCategoryNames()} onItemChanged={e => {
                            setSelectedCategory(e.currentTarget.value);
                        }} value={selectedCategory} />
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={selectedCategory.length === 0}>
                        <Form noValidate validated={validated} onSubmit={onSubmit}>
                            {cat &&
                                <BasicUserData authProvider={app.authProvider!} category={selectedCategory}
                                    groupsDef={[
                                        { name: cat.mainGroup.name, id: cat.mainGroup.id },
                                        { name: cat.licenseGroup.name, id: cat.licenseGroup.id }
                                    ]}
                                    onValueChanged={(value) => {
                                        setBasicUserDataValues(value);
                                    }}
                                />
                            }
                            <p />
                            <div className="d-flex flex-row justify-content-between">
                                <Button className="mr-3" variant="success" hidden={!accountSuccessfullyCreated} onClick={e => setShowCreatedAccount(true)} >
                                    Kontodetails anzeigen
                                </Button>
                                <ButtonProgress className="mr-3" variant="primary" type="submit" disabled={createAccountInProgress}
                                    hidden={selectedCategory.length === 0 || accountSuccessfullyCreated}
                                    buttonText="Konto hinzufügen" isInProgress={createAccountInProgress}
                                />
                                <Button className="mr-3" variant="secondary" type="reset" hidden={selectedCategory.length === 0} onClick={() => {
                                    setAccountSuccessfullyCreated(false);
                                    setSelectedCategory('');
                                }}>
                                    Formular zurücksetzen
                                </Button>
                            </div>
                        </Form>
                        <p />
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.write">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
            <OcCreatedUserAccountDetails category={selectedCategory} user={createdUserAccount!} groups={assignedGroups}
                show={showCreatedAccount} placement="end" onHide={() => setShowCreatedAccount(false)} />
        </div>
    );
}