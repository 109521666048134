import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Group } from "@microsoft/microsoft-graph-types";
import { useState } from "react";
import { getCategory, getCategoryNames } from "../Config/config";
import { getUserAccountsByCategory, getUserAccountsByIds, updateUsersSpecialProps } from "./graphService";

export interface Account {
    [key: string]: string;
}

export const useGetAccounts = (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
    // Return user accounts of the specified category
    //
    const [accounts, setAccounts] = useState<Account[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [maxCount, setMaxCount] = useState(0);
    const [count, setCount] = useState(0);
    const [isDone, setIsDone] = useState(false);

    const getData = async (category: string, ids?: string[], passwords?: string[]) => {
        try {
            if (category) {
                setIsLoading(true);
                setAccounts([]);
                let uacs = undefined;
                
                const cat = getCategory(category);
                if (ids) {
                    const selectStr = cat?.exportSelect.join(',') + ',memberOf';
                    uacs = await getUserAccountsByIds(authProvider, selectStr!, ids, passwords, (done, maxCount, count) => {
                        setMaxCount(maxCount);
                        setCount(count);
                        setIsDone(done);
                    });

                } else {
                    uacs = await getUserAccountsByCategory(authProvider, category, true, null, (done, maxCount, count) => {
                        setMaxCount(maxCount);
                        setCount(count);
                        setIsDone(done);
                    });
                }

                let accs: Account[] = [];
                let maxCountGroups = 0;
                if (uacs && cat) {
                    for (const ua of uacs) {
                        let ac: Account = {};
                        for (let i = 0; i < cat?.exportHeader.length!; ++i) {
                            const hAc = cat?.exportHeader[i]!;
                            const hUa = cat?.exportSelect[i]!;
                            const hUaIdx = hUa as keyof typeof ua;
                            ac[hAc] = ua[hUaIdx] as string;
                        }

                        if (passwords) {
                            ac[cat.passwordHeader] = ua.passwordProfile?.password ? ua.passwordProfile?.password : '';
                        }

                        if (ua.memberOf) {
                            let grps = (ua.memberOf as Group[]).map((g: Group) => {
                                // if (!g.groupTypes?.includes('Unified')) { // don't include Office 365
                                    return g.displayName;
                                // }
                                // return '';
                            }) as string[];
                            grps = grps.filter((g) => {
                                if (g)
                                    return true;
                                return false;
                            });
                            grps.sort((g1, g2) => {
                                return g1?.localeCompare(g2);
                            });
                            for (let i = 0; i < grps.length; ++i) {
                                const hAc = `Gruppe ${i + 1}`;
                                ac[hAc] = grps[i];
                            }
                            if (grps.length > maxCountGroups)
                                maxCountGroups = grps.length;
                        }
                        accs.push(ac);
                    }
                    accs.sort((a1, a2) => {
                        const f = cat?.exportHeader[0]!;
                        if (a1[f] && a2[f]) {
                            return a1[f].localeCompare(a2[f]);
                        }
                        return 0;
                    });

                    let grpHeader: string[] = [];
                    for (let i = 0; i < maxCountGroups; ++i) {
                        grpHeader.push(`Gruppe ${i + 1}`);
                    }
                    setAccounts([...accs]);
                }
            }
        } finally {
            setIsLoading(false);
        }
    }

    const start = (category: string, ids?: string[], passwords?: string[]) => {
        setIsDone(false);
        setIsLoading(false);
        getData(category, ids, passwords);
    }

    const reset = () => {
        setIsDone(false);
        setIsLoading(false);
        setAccounts([]);
    }

    return { start, reset, accounts, isLoading, maxCount, count, isDone };
}

export const useUpdateUsersSpecialProps = (authProvider: AuthCodeMSALBrowserAuthenticationProvider) => {
    // Update special props on all users of this category. 
    //
    const [isLoading, setIsLoading] = useState(false);
    const [maxCount, setMaxCount] = useState(0);
    const [count, setCount] = useState(0);
    const [processingCategory, setProcessingCategory] = useState('');
    const [isDone, setIsDone] = useState(false);

    const getData = async (category?: string) => {
        try {
            let allCategories = getCategoryNames();
            if (category) {
                allCategories = [category];
            }
            setIsLoading(true);
            // let uacsPromises = [];
            for (const currentCategory of allCategories) {
                await updateUsersSpecialProps(authProvider, currentCategory, (procCategory, _done, maxCount, count) => {
                    setMaxCount(maxCount);
                    setCount(count);
                    setProcessingCategory(procCategory);
                });
                // uacsPromises.push(uacs);
            }
            // await Promise.all(uacsPromises);
        } finally {
            setIsLoading(false);
            setIsDone(true);
        }
    }

    const start = (category?: string) => {
        setIsLoading(false);
        setIsDone(false);
        getData(category);
    }

    return { start, isLoading, processingCategory, maxCount, count, isDone };
}

