import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, FormGroup, Row } from 'react-bootstrap';
import { getDepartments } from '../AzAdAccess/graphService';
import { config, getCategory } from '../Config/config';
import { OcFindGroup } from '../Pages/OcFindGroup';
import { checkUpn, makeDisplayName, makeUpn } from '../Utils/createUser';
import { AutoCompleteInput } from './AutoCompleteInput';
import { Tag, TagGroup } from './Tags';

export interface BasicUserDataValues {
    givenName?: string,
    surname?: string,
    employeeId?: string,
    displayName?: string,
    userPrincipalName?: string,
    department?: string,
    groups?: { name: string, id: string }[]
}

export interface BasicUserDataProps {
    authProvider: AuthCodeMSALBrowserAuthenticationProvider,
    category: string,
    givenNameDef?: string,
    surnameDef?: string,
    employeeIdDef?: string,
    displayNameDef?: string,
    userPrincipalNameDef?: string,
    departmentDef?: string,
    groupsDef?: { name: string, id: string }[],
    onValueChanged?: (value: BasicUserDataValues) => void,
    upnReadOnly?: boolean
}

export const BasicUserData = (props: BasicUserDataProps) => {
    const [givenName, setGivenName] = useState(props.givenNameDef);
    const [surname, setSurname] = useState(props.surnameDef);
    const [uid, setUid] = useState(props.employeeIdDef);
    const [displayName, setDisplayName] = useState(props.displayNameDef);
    const [upn, setUpn] = useState(props.userPrincipalNameDef);
    const [department, setDepartment] = useState(props.departmentDef);
    const [groups, setGroups] = useState<{ name: string, id: string }[]>([]);
    const [showFindGroup, setShowFindGroup] = useState(false);

    const cat = getCategory(props.category);
    const onValueChanged = props.onValueChanged;
    const isDepartmentUsed = cat ? cat.importSelect.includes('department') : false;

    useEffect(() => {
        if (props.groupsDef) {
            setGroups([...props.groupsDef]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.category]);

    useEffect(() => {
        const val: BasicUserDataValues = {
            givenName: givenName,
            surname: surname,
            employeeId: uid,
            displayName: displayName,
            userPrincipalName: upn,
            department: department,
            groups: groups
        };
        onValueChanged && onValueChanged(val);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [department, displayName, givenName, surname, uid, upn, groups]);

    const formName =
        <Row className="mt-0">
            <Col>
                <FloatingLabel label="Vorname">
                    <Form.Control name="givenName" type="text" defaultValue={props.givenNameDef}
                        onChange={e => setGivenName(e.currentTarget.value)} />
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel label="Familienname">
                    <Form.Control style={{ textTransform: 'uppercase' }} type="text" defaultValue={props.surnameDef}
                        onChange={e => setSurname(e.currentTarget.value)}
                    />
                </FloatingLabel>
            </Col>
        </Row>

    const formDisplayNameAndUid =
        <Row className="mt-3">
            <Col>
                <FloatingLabel label="Anzeigename">
                    <Form.Control required type="text" autoComplete="off" defaultValue={props.displayNameDef}
                        onChange={e => setDisplayName(e.currentTarget.value)}
                        onFocus={(e) => {
                            if (e.currentTarget.value.length === 0) {
                                e.currentTarget.value = makeDisplayName(givenName, surname);
                                setDisplayName(e.currentTarget.value);
                            }
                        }}
                        onBlur={(e) => {
                            if (e.currentTarget.value.length === 0) {
                                e.currentTarget.value = makeDisplayName(givenName, surname);
                                setDisplayName(e.currentTarget.value);
                            }
                        }}
                    />
                </FloatingLabel>
            </Col>
            <Col>
                <FloatingLabel label="UID">
                    <Form.Control type="text" autoComplete="off"
                        maxLength={config.uidMaxLen} defaultValue={props.employeeIdDef}
                        onChange={e => setUid(e.currentTarget.value)} />
                </FloatingLabel>
            </Col>
        </Row>

    const formAccount =
        <Row className="mt-3">
            <Col>
                <FloatingLabel label="Konto">
                    <Form.Control readOnly={props.upnReadOnly} required type="email" autoComplete="off" defaultValue={props.userPrincipalNameDef}
                        onChange={e => setUpn(e.currentTarget.value)}
                        onFocus={(e) => {
                            if (!props.upnReadOnly) {
                                e.persist();
                                const check = async (upn: string | undefined) => {
                                    if (upn) {
                                        const validUpn = await checkUpn(props.authProvider, cat, upn);
                                        e.target.value = validUpn;
                                        setUpn(e.target.value);
                                    }
                                }
                                if (!upn) {
                                    const u = makeUpn(cat, givenName, surname, displayName);
                                    check(u);
                                } else {
                                    check(upn)
                                }
                            }
                        }}
                        onBlur={(e) => {
                            if (!props.upnReadOnly) {
                                e.persist();
                                const check = async (upn: string | undefined) => {
                                    if (upn) {
                                        const validUpn = await checkUpn(props.authProvider, cat, upn);
                                        e.target.value = validUpn;
                                        setUpn(e.target.value);
                                    }
                                }
                                if (!upn) {
                                    const u = makeUpn(cat, givenName, surname, displayName);
                                    check(u);
                                } else {
                                    check(upn)
                                }
                            }
                        }}
                    />
                </FloatingLabel>
            </Col>
        </Row>

    const formDepartment =
        <Row className="mt-3" hidden={!isDepartmentUsed}>
            <Col>
                <FloatingLabel label="Klasse">
                    <AutoCompleteInput
                        uppercase
                        defaultValue={props.departmentDef}
                        fetchOptions={(startsWith: string) => {
                            return getDepartments(props.authProvider, startsWith);
                        }}
                        onValueChanged={(value) => {
                            setDepartment(value);
                        }}
                    />
                </FloatingLabel>
            </Col>
        </Row>

    const formGroups =
        <Row className="mt-3">
            <Col>
                <TagGroup title="Gruppen"
                    onAdd={() => {
                        setShowFindGroup(true);
                    }}>
                    {groups && groups.map((g) =>
                        <Tag
                            key={g.id} name={g.name} onClick={() => {
                                const upd = [...groups];
                                const idx = upd.indexOf(g);
                                if (idx >= 0) {
                                    upd.splice(idx, 1);
                                    setGroups(x => [...upd]);
                                }
                            }}
                        />
                    )}
                </TagGroup>
            </Col>
        </Row>

    if (!cat) return null;
    return (
        <FormGroup>
            {formName}
            {formDisplayNameAndUid}
            {formAccount}
            {formDepartment}
            {formGroups}
            <OcFindGroup show={showFindGroup} placement="end" onHide={() => setShowFindGroup(false)}
                onAdd={(grp) => {
                    const g = { name: grp.displayName!, id: grp.id! }
                    const upd = [...groups];
                    const fg = upd.find(o => o.id === g.id)
                    if (fg) {
                        return false;
                    }
                    upd.push(g);
                    setGroups(x => [...upd]);
                    return true;
                }}
            />
        </FormGroup>
    );
}
