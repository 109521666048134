import Navbar from "react-bootstrap/Navbar";

export const Footer = () => {
    return (
        <Navbar fixed="bottom" bg="light" variant="light" >
            <Navbar.Text className="small ms-3">
                Copyright &copy; 2022, marinits.net
            </Navbar.Text>
            <Navbar.Collapse className="justify-content-end mr-auto small">
                <Navbar.Text>
                    Version {process.env.REACT_APP_VERSION} &nbsp; &nbsp;
                </Navbar.Text>
            </Navbar.Collapse>
        </Navbar>
    );
}