import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { User } from "@microsoft/microsoft-graph-types";
import { getUser, updateUser } from "../AzAdAccess/graphService";
import { getCsvHeaderNameForImportSelect } from "../Config/config";
import { LogEntryType } from "./logDb";

export const updUser = async (
        authProvider: AuthCodeMSALBrowserAuthenticationProvider, cat: any, row: Record<string, string>, 
        assignedFields: Record<string, string>, logTopic: string, addLogEntry: (lent: LogEntryType, ltopic: string, txt: string) => void) => {

    // Update the properties of an existing user. If the user does not exist, it is _not_ created!
    //
    const upnHeader = getCsvHeaderNameForImportSelect(cat!, 'userPrincipalName');
    const upnCsvHeader = assignedFields[upnHeader!];
    if (!upnCsvHeader) {
        addLogEntry(LogEntryType.Error, logTopic, `Kritischer Fehler: Spalte <b>${upnHeader}</b> muss zugewiesen sein!`);
        return false; // TBD
    }
    const upn = row[upnCsvHeader];
    if (upn) {
        const azUser = await getUser(authProvider!, upn, (cat?.importSelect!).join(','));
        if (azUser) {
            const res = await updUserProperties(authProvider, cat, azUser, row, assignedFields, logTopic, addLogEntry);
            if (!res) {
                addLogEntry(LogEntryType.Error, logTopic, `Konto ${azUser.userPrincipalName}: Aktualiserung fehlgeschlagen.`);
            }
        } else {
            addLogEntry(LogEntryType.Warning, logTopic, `Konto <b>${upn}</b> nicht in AzureAD vorhanden.`);
        }
    } else {
        addLogEntry(LogEntryType.Warning, logTopic, `Spalte <b>${upnHeader}</b> enthält in dieser Zeile keinen Wert: <em>${JSON.stringify(row, null, 1)}</em>`);
    }
    return true;
}

const updUserProperties = async (
        authProvider: AuthCodeMSALBrowserAuthenticationProvider, cat: any, azUser: User, row: Record<string, string>, 
        assignedFields: Record<string, string>, logTopic: string, addLogEntry: (lent: LogEntryType, ltopic: string, txt: string) => void) => {
    let updProps: Record<string, string> = {};
    for (const sel of cat?.importSelect!) {
        const header = getCsvHeaderNameForImportSelect(cat!, sel);
        const csvVal = row[assignedFields[header!]];

        const selIdx = sel as keyof typeof azUser;
        const azVal = azUser[selIdx];

        if (csvVal) {
            const csvValTrimmed = csvVal.trim();
            if (azVal !== csvValTrimmed) {
                updProps[selIdx] = csvValTrimmed;
                addLogEntry(LogEntryType.Success, logTopic,
                    `Konto ${azUser.userPrincipalName}: <b>${header}</b> aktualisiert von <em>${azVal}</em> auf <em>${csvValTrimmed}</em>`);
            }
        }
    }
    if (Object.keys(updProps).length > 0) {
        return await updateUser(authProvider!, azUser.userPrincipalName!, updProps);
    }
    return true;
}

export const updUserSpecialProperties = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider, cat: any, azUser: User) => {
    for (const prop of cat.specialProperties) {
        await updateUser(authProvider!, azUser.userPrincipalName!, prop);
    }
}