import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";


export interface CreatedUserAccountDetailsProps {
    category: string;
    user: User;
    groups?: { name: string, id: string }[];
    hideFooter?: boolean;
}

export const CreatedUserAccountDetails = (props: CreatedUserAccountDetailsProps) => {
    const { category, user, groups, hideFooter, ...otherProps } = props;
    const [clipboardDone, setClipboardDone] = useState(false);

    const createClipboardContent = () => {
        let text = "";
        text += `Kategorie          : ${user.jobTitle}\r\n`;
        text += `Konto              : ${user.userPrincipalName}\r\n`;
        text += `Vorname            : ${user.givenName}\r\n`;
        text += `Nachname           : ${user.surname}\r\n`;
        if (user.passwordProfile?.password && user.passwordProfile?.password.length > 0) {
            text += `Kennwort           : ${user.passwordProfile?.password}\r\n`;
        }
        if (user.department && user.department.length > 0) {
            text += `Klasse             : ${user.department}\r\n`;
        }
        if (user.employeeId && user.employeeId.length > 0) {
            text += `UID                : ${user.employeeId}\r\n`;
        }
        if (groups) {
            text += `Gruppen            : ${makeGroupString(groups)}`;
        }
        return text;
    };

    const makeGroupString = (groups: { name: string, id: string }[]) => {
        return groups.map((g) => { return g.name; }).join(', ');
    }

    useEffect(() => {
        setTimeout(() => { setClipboardDone(false) }, 800);
    }, [clipboardDone]);

    return (
        <Card className="bg-light mb-3 small" {...otherProps}>
            <Card.Body>
                <Card.Title>{`${user.givenName} ${user.surname}`}</Card.Title>
                <Card.Text>
                    <strong>Kategorie: </strong><span>{user.jobTitle}</span>&nbsp;
                    <br />
                    <strong>Konto: </strong><span>{user.userPrincipalName}</span>&nbsp;
                    <br />
                    <strong>Vorname: </strong><span>{user.givenName}</span>&nbsp;
                    <br />
                    <strong>Nachname: </strong><span>{user.surname}</span>&nbsp;
                    <br />
                    {user.passwordProfile?.password && user.passwordProfile?.password.length > 0 ?
                        <>
                            <strong>Kennwort: </strong><span>{user.passwordProfile?.password}</span>&nbsp;
                            <br />
                        </>
                        : ''
                    }
                    {user.department && user.department.length > 0 ?
                        <>
                            <strong>Klasse: </strong><span>{user.department}</span>&nbsp;
                            <br />
                        </>
                        : ''
                    }
                    {user.employeeId && user.employeeId.length > 0 ?
                        <>
                            <strong>UID: </strong><span>{user.employeeId}</span>&nbsp;
                            <br />
                        </>
                        : ''
                    }
                    {groups ?
                        <>
                            <strong>Gruppen: </strong><span>{makeGroupString(groups)}</span>&nbsp;
                            <br />
                        </>
                        : ''
                    }
                </Card.Text>
            </Card.Body>
            {!hideFooter &&
                <Card.Footer className="small">
                    <CopyToClipboard text={createClipboardContent()} onCopy={() => setClipboardDone(true)}>
                        <Button className="float-right" size="sm" variant="outline-primary">
                            {clipboardDone && <Spinner as="span" animation="grow" size="sm" />} Kopieren
                        </Button>
                    </CopyToClipboard>
                </Card.Footer>
            }
        </Card>
    );
}
