import { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export interface CsvHeaderAssignmentProps {
    availableFields: string[],
    requiredFields: string[],
    titleAvailableFields?: string,
    titleAssignment?: string,
    onAssignment?: (assignment: { [name: string]: string }) => void
}

export const CsvHeaderAssignment = (props: CsvHeaderAssignmentProps) => {
    const [assignedFields, setAssignedFields] = useState<{ [name: string]: string }>({});

    const preAssignHeaders = () => {
        // eslint-disable-next-line array-callback-return
        props.requiredFields.map((eh) => {
            if (props.availableFields.includes(eh)) {
                const upd: { [name: string]: string } = {};
                upd[eh] = eh;
                setAssignedFields(x => ({
                    ...x,
                    ...upd
                }));
            }
        });
    }

    useEffect(() => {
        if (props.availableFields && props.requiredFields) {
            preAssignHeaders();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.availableFields, props.requiredFields]);

    useEffect(() => {
        if (props.onAssignment) {
            props.onAssignment(assignedFields);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignedFields]);

    if (!(props.requiredFields && props.availableFields)) {
        return null;
    } else return (
        <div>
            {props.titleAvailableFields}
            <DndProvider backend={HTML5Backend}>
                <div className="field-container">
                    {props.availableFields.map((f) => <DragableHeader key={f} headerName={f} dropResult={(impField, expField) => {
                        const upd: { [name: string]: string } = {};
                        upd[expField] = impField;
                        setAssignedFields(x => ({
                            ...x,
                            ...upd
                        }));
                    }} />)}
                </div>
                <p />
                {props.titleAssignment}
                <Table bordered size="sm" style={{ width: "5%" }}>
                    <tbody>
                        <tr>
                            {props.requiredFields.map((eh) =>
                                <td key={'t' + eh}>
                                    <DragableHeaderTarget headerName={eh} />
                                </td>
                            )}
                        </tr>
                        <tr>
                            {props.requiredFields.map((eh) =>
                                <td key={'a' + eh}>
                                    <div className="field-assigned-item">
                                        {assignedFields[eh] ?
                                            <DropdownButton title={assignedFields[eh]} size="sm" variant="secondary">
                                                <Dropdown.Item onClick={() => {
                                                    const upd = { ...assignedFields };
                                                    delete upd[eh];
                                                    setAssignedFields(x => ({
                                                        ...upd
                                                    }));
                                                }}>
                                                    <small>Entfernen</small>
                                                </Dropdown.Item>
                                            </DropdownButton> : '-'}
                                    </div>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </Table>
            </DndProvider>
        </div>
    );
}

interface DragableHeaderProps {
    headerName: string,
    dropResult?: (impField: string, expField: string) => void
}

interface DragableHeaderDropProps {
    headerName: string
}

interface DragableHeaderDropResult {
    targetName: string
}

const DragableHeader = (props: DragableHeaderProps) => {
    const [, drag] = useDrag(() => ({
        type: 'header',
        item: props,
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DragableHeaderDropResult>();
            if (props.dropResult) {
                props.dropResult(item.headerName, dropResult?.targetName!);
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    return (
        <div ref={drag} className="field-item" data-testid={'header'}>{props.headerName}</div>
    );
}

const DragableHeaderTarget = (props: DragableHeaderDropProps) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'header',
        drop: () => ({
            targetName: props.headerName
        }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem()
        }),
    }))

    const isActive = isOver && canDrop;
    return (
        <div className={isActive ? "field-drop-zone-active" : "field-drop-zone"} ref={drop} data-testid={props.headerName}>
            {props.headerName}
        </div>
    )
}
