import { Offcanvas, OffcanvasProps } from "react-bootstrap";
import { DisplayCsv, DisplayCsvProps } from "../UIElements/DisplayCsv";

export interface OcDisplayCsvProps extends DisplayCsvProps, OffcanvasProps { 
    title: string
}

export const OcDisplayCsv = (props: OcDisplayCsvProps) => {
    const { height, header, data, title, ...otherProps } = props;
    return (
        <Offcanvas {...otherProps}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <DisplayCsv height={height} header={header} data={data}/>
            </Offcanvas.Body>
        </Offcanvas>
    );
}