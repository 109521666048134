import { Table } from "react-bootstrap";

export interface DisplayCsvProps {
    height?: number | string;
    header: string[],
    data: Record<string, string>[]
}

export const DisplayCsv = (props: DisplayCsvProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { height, header, data, ...otherProps } = props;

    const cols = (row: Record<string, string>) => {
        let cols = [];
        let count = 0;
        for (const col in row) {
            const colIdx: keyof typeof row = col;
            cols.push(
                <td key={++count + 10000}>
                    {row[colIdx]}
                </td>
            );
        }
        return cols;
    }

    const rows = () => {
        let count = 0;
        return data.map((row) => {
            return (
                <tr key={++count}>
                    {cols(row)}
                </tr>
            );
        });
    }

    let hCount = 0;
    return (
        <div style={{ overflowY: "auto", height: height }} >
            <Table size="sm" style={{ fontSize: "small" }} striped bordered responsive="sm">
                <thead>
                    <tr>
                        { header && header.map(h => <td key={++hCount}>{h}</td>)}
                    </tr>
                </thead>
                <tbody>
                    {rows()}
                </tbody>
            </Table>
        </div>
    );
}