import Dropzone, { DropzoneProps } from "react-dropzone";


export const DropzoneCsv = (props: DropzoneProps) => {

    return (
        <Dropzone { ...props}>
            {({ getRootProps, getInputProps }) => (
                <section className="drop-zone">
                    <div className="inner-drop-zone" {...getRootProps()}>
                        <input {...getInputProps()} />
                        CSV-Datei hier ablegen oder hier klicken
                    </div>
                </section>
            )}
        </Dropzone>
    );
}