import FileSaver from "file-saver";

export const saveBlobToFile = (blob: Blob, filename: string) => {
    FileSaver.saveAs(blob, filename);
};

export const saveStringToFile = (data: string, filename: string, fileType: string) => {
    const blob = new Blob([data], { type: fileType });
    saveBlobToFile(blob, filename);
};
