import { Card, CardProps, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppContext } from "../App/AppContext";
import { IsAppRoleAssigned } from "../App/AppRolesAssigned";


export const CardTasksBulk = (props: CardProps) => {
    const app = useAppContext();

    return (
        <Card {...props} >
            <Card.Header>Aufgaben (Bulk)</Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write.bulk')} >
                    <Link to="import">Konten importieren</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.read')} >
                    <Link to="export">Konten exportieren</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.read')} >
                    <Link to="diff">Konten abgleichen</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.delete.bulk')} >
                    <Link to="bulkdel">Konten löschen</Link>
                </ListGroup.Item>
                <ListGroup.Item hidden={!IsAppRoleAssigned(app.appRoles!, 'access.write.bulk')} >
                    <Link to="bulkupdspecial">Spezielle Eigenschaften aktualisieren</Link>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
}