import { useEffect, useState } from "react";
import { Alert, Button, ListGroup, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { deleteUser, isUserExists } from "../AzAdAccess/graphService";
import { config, getCsvHeaderNameForDeleteSelect } from "../Config/config";
import { CsvHeaderAssignment } from "../UIElements/CsvHeaderAssignment";
import { DropzoneCsv } from "../UIElements/DropzoneCsv";
import { LogDisp } from "../UIElements/LogDisp";
import { readCsvFile } from "../Utils/csvUtils";
import { epochTimeMs, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";
import { OcDisplayCsv } from "./OcDisplayCsv";

export const DeleteAccounts = () => {

    const logTopic = 'Konten löschen';
    const app = useAppContext();
    const [fields, setFields] = useState<string[]>();
    const [csvData, setCsvData] = useState<any[]>([]);
    const [csvFileName, setCsvFileName] = useState('');
    const [assignedFields, setAssignedFields] = useState<{ [name: string]: string }>({});
    const [logDispStartTime, setLogDispStartTime] = useState(0);
    const [showDispCsv, setShowDispCsv] = useState(false);
    const [isActionOngoing, setIsActionOngoing] = useState(false);
    const [progressAction, setProgressAction] = useState(0);

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    const doAction = async () => {
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
        setIsActionOngoing(true);
        const header = getCsvHeaderNameForDeleteSelect('userPrincipalName');
        const upnCsv = assignedFields[header!];

        if (upnCsv) {
            let count = 0;
            for (const row of csvData) {
                const upn = row[upnCsv];

                const isUser = await isUserExists(app.authProvider!, 'userPrincipalName', upn);
                if (isUser) {
                    const isDeleted = await deleteUser(app.authProvider!, upn);
                    if (isDeleted) {
                        addLogEntry(LogEntryType.Success, logTopic, `Das Konto <em>${upn}</em> wurde erfolgreich gelöscht.`);
                    } else {
                        addLogEntry(LogEntryType.Warning, logTopic, `Das Konto <em>${upn}</em> konnte nicht gelöscht werden.`);
                    }

                } else {
                    addLogEntry(LogEntryType.Warning, logTopic, `Das Konto <em>${upn}</em> exsitert nicht.`);
                }

                ++count;
                setProgressAction(Math.round(100 * count / csvData.length));
            }
        } else {
            addLogEntry(LogEntryType.Error, logTopic, `Keine Konto-Feld-Zuweisung vorhanden.`);
        }

        setIsActionOngoing(false);
        addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
    }

    return (
        <div className="page-margin page-maxwidth">
            <h3>Konten löschen</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.delete.bulk" >
                <ListGroup>
                    <ListGroup.Item className="border-0" >
                        <DropzoneCsv onDrop={(acceptedFiles) => {
                            setCsvFileName(acceptedFiles[0].name);
                            readCsvFile(acceptedFiles[0], (res) => {
                                setFields(res.meta.fields);
                                setCsvData(res.data);
                            });
                        }} />
                        {csvFileName &&
                            <span style={{ verticalAlign: "bottom" }}>
                                CSV-Datei:&nbsp;
                                <Button variant="link" style={{ padding: 0 }} onClick={() => setShowDispCsv(true)}>
                                    {csvFileName}
                                </Button>
                                <br />
                                Anzahl der zu löschenden Konten: {csvData.length}
                            </span>
                        }
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={!fields || fields.length === 0}>
                        <div>
                            <CsvHeaderAssignment
                                availableFields={fields!}
                                requiredFields={config.deleteHeader}
                                titleAvailableFields="Felder der importierten CSV-Datei:"
                                titleAssignment="Konto-Feld Zuweisung:"
                                onAssignment={(assignment) => {
                                    setAssignedFields(assignment);
                                }} />
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0" hidden={!fields || fields.length === 0}>
                        <div className="d-flex flex-row justify-content-between">
                            <Button className="mr-3" onClick={doAction} disabled={isActionOngoing} >Konten löschen ...</Button>
                            <Button className="mr-3" variant="secondary" type="reset" onClick={() => {
                                setCsvData([]);
                                setFields([]);
                                setCsvFileName('');
                                setLogDispStartTime(epochTimeMs());
                            }}>
                                Formular zurücksetzen
                            </Button>
                        </div>
                        <p />
                        <div hidden={!(isActionOngoing)} >
                            Konten werden gelöscht ...
                            <ProgressBar now={progressAction} label={`${progressAction}%`} />
                            <p />
                        </div>
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.delete.bulk">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
            <OcDisplayCsv header={fields!} data={csvData} title={csvFileName}
                show={showDispCsv} placement="bottom" onHide={() => setShowDispCsv(false)} />
        </div>
    );
}
