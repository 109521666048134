import { Button, ButtonProps, Spinner } from "react-bootstrap";

export interface ButtonProgressProps extends ButtonProps {
    buttonText: string;
    isInProgress: boolean;
    isDoneOk?: boolean;
    isDoneError?: boolean;
}

export const ButtonProgress = (props: ButtonProgressProps) => {
    let { buttonText, isInProgress, isDoneOk, isDoneError, ...otherProps } = props;
    return(
        <div className="d-flex flex-nowrap align-items-center">  
            <Button className="text-nowrap" { ...otherProps}>
                {buttonText}
                {isInProgress && <Spinner as="span" animation="border" size="sm" className="ms-2" />}
            </Button>
            {isDoneOk && <i className="far fa-check-circle fa-2x ms-2" style={{color: 'green'}} />}
            {isDoneError && <i className="far fa-times fa-2x ms-2" style={{color: 'red'}} />}
        </div>
    );
}
