import { useRef, useState } from "react";
import { Form, FormControlProps, ListGroup } from "react-bootstrap";

export interface AutoCompleteInputProps extends FormControlProps {
    fetchOptions: (startsWith: string) => Promise<any[] | undefined>,
    fetchOptionsDisplayMembers?: string[],
    fetchOptionsValueMember?: string,
    onValueChanged?: (value: string | any) => void,
    uppercase?: boolean,
    isChangeValueOnOptionSelectOnly?: boolean
}

const getDisplayString = (option: any, displayMembers?: string[]) => {
    let fdl = '';
    if (option && displayMembers) {
        displayMembers.forEach((dm) => {
            if (fdl.length > 0) {
                if (option[dm]) {
                    fdl += ', ' + option[dm] as string;
                }
            } else {
                if (option[dm]) {
                    fdl += option[dm];
                }
            }
        });
    } else {
        fdl = option || '';
    }
    return fdl;
}

export const AutoCompleteInput = (props: AutoCompleteInputProps) => {
    const [options, setOptions] = useState<any>([]);
    const [hideOptions, setHideOptions] = useState(false);
    const lastOptionRequest = useRef('');
    const refInput = useRef<HTMLInputElement>(null);

    let { fetchOptions, fetchOptionsDisplayMembers, fetchOptionsValueMember, onValueChanged, 
          uppercase, isChangeValueOnOptionSelectOnly, ...otherProps } = props;

    let mystyle = {};
    if (uppercase) {
        mystyle = { textTransform: 'uppercase' };
    }
    
    return (
        <>
            <Form.Control type="text" ref={refInput} style={mystyle} {...otherProps}
                onChange={(e) => {
                    const updOptions = async (startsWith: string) => {
                        if (startsWith.length > 0) {
                            const options = await fetchOptions(startsWith);
                            if (options && lastOptionRequest.current === startsWith)
                                setOptions(options);
                        }
                    };
                    if (e.currentTarget.value && e.currentTarget.value.length > 0) {
                        lastOptionRequest.current = e.currentTarget.value;
                        updOptions(lastOptionRequest.current);
                        setHideOptions(false);
                    } else {
                        lastOptionRequest.current = '';
                        setHideOptions(true);
                    }
                    if (!isChangeValueOnOptionSelectOnly && onValueChanged) {
                        onValueChanged(e.currentTarget.value);
                    }
                }}
                onBlur={(e) => {
                    setTimeout(() => {
                        setHideOptions(true);
                    }, 200);
                }} 
            />
            <div className={ options.length === 0 ? "" : "input-options" } hidden={hideOptions}>
                <ListGroup variant="flush">
                    {options.map((opt: any) => {
                        return (
                            <ListGroup.Item className="input-options-item" key={options.indexOf(opt)} as="div" action
                                onClick={(e) => {
                                    if (opt && refInput.current !== null) {
                                        refInput.current.value = fetchOptionsValueMember ? opt[fetchOptionsValueMember] : opt;
                                        onValueChanged && onValueChanged(refInput.current.value);
                                    }
                                }}
                            >
                                { getDisplayString(opt, fetchOptionsDisplayMembers) }
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            </div>
        </>

    );
}