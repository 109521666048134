import { useEffect, useState } from 'react';
import { Form, FormSelectProps } from 'react-bootstrap';

export interface SelectorItem {
    name: string;
    value: string;
}

export interface SelectorProps extends FormSelectProps {
    title?: string;
    items: SelectorItem[] | string[];
    isDisabled?: boolean;
    onItemChanged: (event: any) => void;
}

export const Selector = (props: SelectorProps) => {
    const [selItems, setSelItems] = useState<Array<SelectorItem>>();

    const { title, items, isDisabled, onItemChanged, ...otherProps } = props;

    useEffect(() => {
        if (items && items.length > 0) {
            if (typeof items[0] === 'string') {
                const sis: Array<SelectorItem> = [];
                // eslint-disable-next-line array-callback-return
                items.map((it) => {
                    const si: SelectorItem = {
                        name: it as string,
                        value: it as string
                    }
                    sis.push(si);
                });
                setSelItems(sis);
            } else {
                const sis: Array<SelectorItem> = (items as Array<SelectorItem>).slice();
                setSelItems(sis);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Select required onChange={onItemChanged} {...otherProps}
                    disabled={isDisabled}>
                    <option value='' disabled hidden>
                        {title ? title : ''}
                    </option>
                    {selItems && selItems.length > 0 ? selItems.map(si => <option value={si.value} key={si.value}>{si.name}</option>) : ''}
                </Form.Select>
            </Form.Group>
        </>
    );
}