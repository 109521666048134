import { Pagination, PaginationProps } from "react-bootstrap";

export interface PaginationExProps extends PaginationProps {
    total: number;
    active: number;
    maxVisibleItems?: number;
    onPageChanged: (activePage: number) => void;
}

export const PaginationEx = (props: PaginationExProps) => {
    let { total, active, maxVisibleItems, onPageChanged, ...otherProps } = props;
    
    if (!maxVisibleItems) maxVisibleItems = 10;
    const start = Math.max(active - Math.round(maxVisibleItems/2.0), 1);
    const end = Math.min(start + maxVisibleItems - 1, total);

    const items = [];
    items.push(<Pagination.First key={'key_pagination_first'} onClick={() => onPageChanged(1)} />);
    items.push(<Pagination.Prev key={'key_pagination_prev'} onClick={() => onPageChanged(Math.max(active - 1, 1))} />);
    if (start > 1) {
        items.push(<Pagination.Ellipsis key={'key_ellipsis_start'} disabled={true}/>);
    }
    for (let i = start; i <= end; ++i) {
        items.push(
            <Pagination.Item key={i} active={i === active} onClick={() => onPageChanged(i)}>
                {i}
            </Pagination.Item>
        );
    }
    if (end < total) {
        items.push(<Pagination.Ellipsis key={'key_ellipsis_end'} disabled={true}/>);
    }
    items.push(<Pagination.Next key={'key_pagination_next'} onClick={() => onPageChanged(Math.min(active + 1, total))} />);
    items.push(<Pagination.Last key={'key_pagination_last'} onClick={() => onPageChanged(total)} />);
    
    return (
        <Pagination { ...otherProps}>
            {items}
        </Pagination>
    );
}
