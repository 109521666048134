import { Card, CardProps, ListGroup, ListGroupItem } from "react-bootstrap";
import { ServiceHealthStatus } from "../AzAdAccess/serviceHealth";
import { config } from "../Config/config";


export interface CardServiceHealthProps extends CardProps {
    serviceHealth: ServiceHealthStatus | undefined;
}

export const CardImportantServiceHealth = (props: CardServiceHealthProps) => {
    let { serviceHealth, ...otherProps } = props;
    const getServiceTrafficLight = (svcName: string) => {
        if (serviceHealth?.operational!.includes(svcName)) {
            return (
                <span>
                    <i className="fa fa-circle" style={{ color: 'green' }} />
                    &nbsp;&nbsp;
                    {svcName}
                </span>
            );
        } else if (serviceHealth?.degraded!.includes(svcName)) {
            return (
                <span>
                    <i className="fa fa-circle" style={{ color: 'orange' }} />
                    &nbsp;&nbsp;
                    {svcName}
                </span>
            );
        } else if (serviceHealth?.degraded!.includes(svcName)) {
            return (
                <span>
                    <i className="fa fa-circle" style={{ color: 'red' }} />
                    &nbsp;&nbsp;
                    {svcName}
                </span>
            );
        } else {
            return (
                <span>
                    <i className="fa fa-circle" style={{ color: 'grey' }} />
                    &nbsp;&nbsp;
                    {svcName}
                </span>
            );
        }
    }

    return (
        <Card {...otherProps}>
            <Card.Header>Wichtige Dienste</Card.Header>
            <ListGroup variant="flush">
                {config.seriveHealthMon.map((svc) => (
                    <ListGroupItem key={svc}>
                        {getServiceTrafficLight(svc)}
                    </ListGroupItem>
                ))}
            </ListGroup>
        </Card>
    );
}