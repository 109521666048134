
export const epochTimeMs = () => {
    return Date.now();
}

export const localDateTimeStringFromEpochMs = (epochMs: number | undefined) => {
    if (epochMs) {
        const d = new Date(epochMs);
        const loc = 'de-AT';
        return `${d.toLocaleDateString(loc)} ${d.toLocaleTimeString(loc)}`;
    }
    return '';
}

export const localTimeStringFromEpochMs = (epochMs: number | undefined) => {
    if (epochMs) {
        const d = new Date(epochMs);
        const loc = 'de-AT';
        return d.toLocaleTimeString(loc);
    }
    return '';
}

export const localDateTimeString = () => {
    const d = new Date();
    const loc = 'de-AT';
    return `${d.toLocaleDateString(loc)} ${d.toLocaleTimeString(loc)}`;
};

export const simpleDateTimeString = () => {
    const d = new Date();
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDay()}_${d.getHours()}_${d.getMinutes()}_${d.getSeconds()}`;
};

export const fileNameWithDate = (basename: string, ext: string) => {
    return `${basename}_${simpleDateTimeString()}.${ext}`;
};
