import { useEffect, useState } from "react";
import { Accordion, Alert, ListGroup, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { AppRoleAssigned, AppRoleNotAssigned } from "../App/AppRolesAssigned";
import { useUpdateUsersSpecialProps } from "../AzAdAccess/accounts";
import { getCategoryNames, getSpecialPropsN } from "../Config/config";
import { ButtonProgress } from "../UIElements/ButtonProgress";
import { LogDisp } from "../UIElements/LogDisp";
import { epochTimeMs, localDateTimeString } from "../Utils/dateTimeUtils";
import { addLogEntry, LogEntryType } from "../Utils/logDb";


export const UpdateAllSpecialProperties = () => {

    const logTopic = 'Spezielle Eigenschaften aktualisieren';
    const app = useAppContext();
    const { start, isLoading, processingCategory, maxCount, count, isDone } = useUpdateUsersSpecialProps(app.authProvider!);
    const [logDispStartTime, setLogDispStartTime] = useState(0);

    const getSpecialProps = (category: string) => {
        const sp = getSpecialPropsN(category);
        if (sp) {
            return (
                <ul key={category + 'ul'} > {
                    sp.map((p) => {
                        const k = Object.keys(p)[0];
                        return <li key={k}>{k}: {p[k]}</li>
                    })}
                </ul>
            );
        }
        return (<></>);
    }

    const specProps = () => {
        const categories = getCategoryNames();
        return (
            <Accordion defaultActiveKey={['0']} alwaysOpen>
                {categories.map((c) => {
                    return (
                        <Accordion.Item eventKey={c} key={c}>
                            <Accordion.Header>{c}</Accordion.Header>
                            <Accordion.Body>{getSpecialProps(c)}</Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        );
    }

    const progress = (): number => {
        if (maxCount > 0) {
            return Math.round(100 * count / maxCount)
        }
        return 0;
    }

    useEffect(() => {
        setLogDispStartTime(epochTimeMs());
    }, []);

    useEffect(() => {
        if (processingCategory && processingCategory.length > 0) {
            addLogEntry(LogEntryType.Info, logTopic, `Kategorie ${processingCategory} wird aktualisiert`);
        }
    }, [processingCategory]);

    useEffect(() => {
        if (isDone) {
            addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde beendet: ${localDateTimeString()}`);
        }
    }, [isDone]);

    return (
        app.user! &&
        <div className="page-margin page-maxwidth">
            <h3>Spezielle Eigenschaften aktualisieren</h3>
            <br />
            <AppRoleAssigned appRoles={app.appRoles!} requiredRole="access.write.bulk" >
                <ListGroup>
                    <ListGroup.Item className="border-0" >
                        {specProps()}
                    </ListGroup.Item>
                    <ListGroup.Item className="border-0">
                        <ButtonProgress
                            buttonText={"Aktualisieren ..."}
                            onClick={e => {
                                start();
                                addLogEntry(LogEntryType.Info, logTopic, `Die Aktion wurde gestartet: ${localDateTimeString()}`);
                            }}
                            isInProgress={isLoading}
                            disabled={isLoading}
                            isDoneOk={isDone}
                        />
                        <p />
                        <div hidden={!isLoading && !isDone} >
                            <ProgressBar now={progress()} label={`${progress()}%`} />
                            <p />
                        </div>
                        <div className="log-disp">
                            <LogDisp height="10rem" startTime={logDispStartTime} />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </AppRoleAssigned>
            <AppRoleNotAssigned appRoles={app.appRoles!} requiredRole="access.write.bulk">
                <Alert variant="danger">
                    Sie besitzen nicht die notwendigen Rechte.
                </Alert>
            </AppRoleNotAssigned>
        </div>
    );
}