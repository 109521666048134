import { useLiveQuery } from "dexie-react-hooks";
import { Offcanvas, OffcanvasProps, Table } from "react-bootstrap";
import { localDateTimeStringFromEpochMs } from "../Utils/dateTimeUtils";
import { logDb } from "../Utils/logDb";
import parse from 'html-react-parser';
import sanitizeHtml from "sanitize-html";
import { getLogEntryTypeIcon } from "../UIElements/LogEntryTypeIcon";
import { PaginationEx } from "../UIElements/PaginationEx";
import { useRef, useState } from "react";


export const OcLogView = (props: OffcanvasProps) => {
    const [curActivePage, setCurActivePage] = useState(1);
    const pageSize = 100;
    const tableRef = useRef<HTMLTableElement>(null);

    const logEntries = useLiveQuery(() =>
        logDb.logEntries
            .reverse()
            .offset((curActivePage - 1) * pageSize)
            .limit(pageSize)
            .toArray()
        , [curActivePage]);

    const countLogEntries = useLiveQuery(() => logDb.logEntries.count());

    const rows = () => {
        return logEntries?.map((ldbe) => {
            return (
                <tr key={ldbe.id}>
                    <td><span className="nowrap">{localDateTimeStringFromEpochMs(ldbe.epochMs)}</span></td>
                    <td>{getLogEntryTypeIcon(ldbe.type)}</td>
                    <td><span className="nowrap">{ldbe.topic}</span></td>
                    <td>{parse(sanitizeHtml(ldbe.text))}</td>
                </tr>
            );
        });
    }

    return (
        <Offcanvas {...props}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <div style={{ display: "flex"}}>
                        Log &nbsp;
                        <PaginationEx
                            size="sm"
                            total={Math.round(countLogEntries! / pageSize + 0.5)}
                            active={curActivePage}
                            onPageChanged={(activePage: number) => {
                                setCurActivePage(activePage);
                                tableRef.current && tableRef.current.scrollIntoView(true);
                            }} />
                    </div>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Table ref={tableRef} size="sm" className="oc-log-table" responsive="sm">
                    <tbody >
                        {rows()}
                    </tbody>
                </Table>
            </Offcanvas.Body>
        </Offcanvas>
    );
}