import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAppContext } from "../App/AppContext";
import { useCountMainGrps } from "../AzAdAccess/categories";
import { useGetServiceHealth } from "../AzAdAccess/serviceHealth";
import { CardCountMainGrps } from "../UIElements/CardCountMainGrps";
import { CardImportantServiceHealth } from "../UIElements/CardImportantServiceHealth";
import { CardOrganisation } from "../UIElements/CardOrganisation";
import { CardServiceHealth } from "../UIElements/CardServiceHealth";
import { CardTasks } from "../UIElements/CardTasks";
import { CardTasksBulk } from "../UIElements/CardTasksBulk";

export const Welcome = () => {

    const app = useAppContext();
    const {cmgr, getData: getDataGrp} = useCountMainGrps(app.authProvider!);
    const {serviceHealth, getData: getDataSvcHealth} = useGetServiceHealth(app.authProvider!);
    const [time, setTime] = useState(Date.now());

    
    useEffect(() => {
        // Refresh every 10 seconds to get current health and accounts state
        //
        const interval = setInterval(() => setTime(Date.now()), 10 * 1000);
        return () => {
            clearInterval(interval);
        };
    });

    useEffect(() => {
        getDataGrp();
        getDataSvcHealth();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    if (app.user) {
        return (
            <div className="page-margin">
                <h3>Willkommen, {app.user?.givenName}!</h3>
                <br />
                <div className="card-container">
                    <CardOrganisation border="primary" className="card-item" />
                    <CardTasks border="primary" className="card-item" />
                    <CardTasksBulk border="primary" className="card-item" />
                    <CardServiceHealth border="primary" className="card-item" serviceHealth={serviceHealth} />
                    <CardImportantServiceHealth border="primary" className="card-item" serviceHealth={serviceHealth} />
                    <CardCountMainGrps border="primary" className="card-item" cmgr={cmgr} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="page-center">
                <Spinner animation="grow" variant="success" />
            </div>
        );
    }
}
