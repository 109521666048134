import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { ServiceHealth } from "@microsoft/microsoft-graph-types";
import { useState } from "react";
import { epochTimeMs } from "../Utils/dateTimeUtils";
import { getHealthOverview } from "./graphService";

export interface ServiceHealthStatus {
    updateEpochMs: number;
    operational: string[],
    degraded: string[],
    interrupted: string[],
    other: string[]
}

export const useGetServiceHealth = (authProvider: AuthCodeMSALBrowserAuthenticationProvider): 
    { serviceHealth: ServiceHealthStatus | undefined, getData: () => Promise<void> } => {
    // Return the count of members for the main group of each category
    //
    const localStorageKey = 'serviceHealth';
    const [serviceHealthLive, setServiceHealthLive] = useState<ServiceHealthStatus>();

    const getData = async () => {
        const sh: ServiceHealth[] = await getHealthOverview(authProvider);
        const shs: ServiceHealthStatus = {
            updateEpochMs: epochTimeMs(),
            operational: sh.filter((s) => s.status === "serviceOperational").map(s => s.service) as string[],
            degraded: sh.filter((s) => s.status === "serviceDegradation").map(s => s.service) as string[],
            interrupted: sh.filter((s) => s.status === "serviceInterruption").map(s => s.service) as string[],
            other: sh.filter((s) => 
                s.status !== "serviceOperational" && s.status !== "serviceDegradation" && 
                s.status !== "serviceInterruption").map(s => s.service) as string[]
        }
        setServiceHealthLive(shs);
        // Cache the value in local storage
        localStorage.setItem(localStorageKey, JSON.stringify(shs));
    }

    let serviceHealth = serviceHealthLive;
    // Get cached data from local storage
    const cachedServiceHealthString = localStorage.getItem(localStorageKey);
    if (cachedServiceHealthString) {
        const cachedServiceHealth: ServiceHealthStatus = JSON.parse(cachedServiceHealthString);
        if (!serviceHealth || cachedServiceHealth.updateEpochMs > serviceHealth.updateEpochMs) {
            serviceHealth = cachedServiceHealth;
        }
    }

    return { serviceHealth, getData };
}
