import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { NullableOption, VerifiedDomain } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { getBannerLogo, getOrganization } from "./graphService";


export interface OrgData {
    id: string | undefined;
    displayName: NullableOption<string> | undefined;
    verifiedDefaultDomain: VerifiedDomain | undefined;
    verifiedOtherDomains?: VerifiedDomain[];
    bannerLogo?: string | undefined;
}

export const getOrganizationData = async (authProvider: AuthCodeMSALBrowserAuthenticationProvider): Promise<OrgData | undefined> => {
    // Return organization of the currently logged in user
    //
    const o = await getOrganization(authProvider);
    const orgData: OrgData = {
        id: o.id,
        displayName: o.displayName,
        verifiedDefaultDomain: o.verifiedDomains?.filter((d) => d.isDefault)[0],
        verifiedOtherDomains: o.verifiedDomains?.filter((d) => d.isDefault !== true),
        bannerLogo: await getBannerLogo(authProvider, o.id!)
    }
    return orgData;
}

export const useGetOrganization = (authProvider: AuthCodeMSALBrowserAuthenticationProvider): OrgData | undefined => {
    // Return organization of the currently logged in user
    //
    const [org, setOrg] = useState<OrgData>();

    const getData = async () => {
        const o = await getOrganizationData(authProvider);
        setOrg(o);
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return org;
}
