import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { useState } from "react";
import { config } from "../Config/config";
import { getCountDirectGroupMembers } from "./graphService";

export const useCountMainGrps = (authProvider: AuthCodeMSALBrowserAuthenticationProvider): 
    { cmgr: { name: string; count: number | undefined; }[], getData: () => Promise<void> } => {
    // Return the count of members for the main group of each category
    //
    const localStorageKey = 'cmgr';
    const [cmgrLive, setCmgrLive] = useState<{name: string, count: number | undefined}[]>([]);

    const getData = async () => {
        const cr = await Promise.all(config.categories.map(async (e) => {
            const count = await getCountDirectGroupMembers(authProvider, e.mainGroup.id);
            const name = e.name;
            return {name, count};
        }));
        setCmgrLive(cr);
        // Cache the value in local storage
        localStorage.setItem(localStorageKey, JSON.stringify(cr));
    }

    let cmgr = cmgrLive;
    if (cmgr.length === 0) {
        // Get cached data from local storage
        const cachedCmgrStr = localStorage.getItem(localStorageKey);
        if (cachedCmgrStr) {
            cmgr = JSON.parse(cachedCmgrStr);
        }
    }

    return {cmgr, getData};
}
